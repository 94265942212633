// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'
// import NewsLetter from '../elements/NewsLetter'
// import PageTitle from '../elements/PageTitle'

// const pricingBlog = [
//   { rate: '49', title: 'Basic', prime: 'premium' },
//   { rate: '59', title: 'Ultra' },
//   { rate: '69', title: 'Pro' },
// ]

// const Pricing = () => {
//   const [hoverEffect, setHoverEffect] = useState(1)
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage={'Pricing'} parentTitle="Pages" />
//         <section
//           className="content-inner rounded-shape-top overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="row">
//               {pricingBlog.map((data, ind) => (
//                 <div className="col-lg-4 col-md-6 m-b30" key={ind}>
//                   <div
//                     className={`pricingtable-wrapper box-hover style-1 ${
//                       ind === hoverEffect ? 'active' : ''
//                     }`}
//                     onMouseEnter={() => setHoverEffect(ind)}
//                   >
//                     <div className="pricingtable-inner">
//                       <div className={`pricingtable-title ${data.prime}`}>
//                         {data.title}
//                       </div>

//                       <div className="pricingtable-price">
//                         <h2 className="pricingtable-bx text-primary">
//                           ${data.rate}
//                           <small>/ Month</small>
//                         </h2>
//                         <p>
//                           A good choice when working remotely With Your Clients
//                         </p>
//                       </div>
//                       <ul className="pricingtable-features">
//                         <li>Review Your Question</li>
//                         <li>Work with Resources</li>
//                         <li>Social Media Marketing</li>
//                         <li>Analysis of Your &quot;I Have&quot;</li>
//                         <li>Support & Mentoring</li>
//                       </ul>
//                       <div className="pricingtable-footer">
//                         <Link
//                           to={'/contact-us'}
//                           className="btn btn-primary btn-skew"
//                         >
//                           <span>Contact Us</span>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>
//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Pricing

import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Check, ChevronLeft, ChevronRight } from 'lucide-react'
import 'swiper/css'
import PageTitle from '../elements/PageTitle'

const carousel = [
  {
    planTitle: 'STARTER',
    price: '4999',
    duration: '1 Month',
    description: [
      'Perfect for beginners or those wanting to try out our facilities',
      'Access to all gym equipment',
      'Free Wi-Fi and locker facilities',
    ],
  },
  {
    planTitle: 'Enthusiast',
    price: '9999',
    duration: '3 Months',
    description: [
      'Deal for short-term fitness goals.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
  {
    planTitle: 'Builder',
    price: '14999',
    duration: '6 Months',
    description: [
      'Designed for dedicated fitness enthusiasts.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
  {
    planTitle: 'Ultimate',
    price: '19999',
    duration: '12 Months',
    description: [
      'Best value for long-term fitness commitment.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
]

const Pricing = () => {
  const navigate = useNavigate()
  const swiperRef = useRef(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Pricing" parentTitle="Pricing" />
        <section className="content-inner overflow-hidden">
          <div className="container">
            <div className="section-head style-1 text-center mb-8">
              <h2 className="title">
                <span className="text-primary">Membership</span> Plans
              </h2>
            </div>

            <div className="relative">
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
                onSlideChange={handleSlideChange}
                slidesPerView={1}
                spaceBetween={30}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="relative pb-4"
              >
                {carousel.map((item, ind) => (
                  <SwiperSlide key={ind} className="h-auto">
                    <div
                      className="dz-card style-3 h-full relative border border-1 p-3 rounded-lg "
                      style={{ boxShadow: '0 10px 20px rgba(0,0,0,0.1)' }}
                    >
                      <div className="text">
                        <h4>₹{item.price}/-</h4>
                        <span>For {item.duration}</span>{' '}
                      </div>
                      <div className="dz-info px-2">
                        <h2 className="dz-title mb-2 mt-0 text-xl font-semibold">
                          <Link
                            to="/user-registration"
                            state={{ selectedPlan: item }}
                          >
                            {item.planTitle}
                          </Link>
                        </h2>
                        <div className="mb-4">
                          {item.description.map((point, index) => (
                            <div
                              key={index}
                              className="flex items-center bg-gray-100 rounded-lg p-2"
                            >
                              <Check
                                className="mr-3 px-1 text-primary"
                                size={20}
                              />
                              <span className="text-gray-700 text-sm">
                                {point}
                              </span>
                            </div>
                          ))}
                        </div>
                        <Link
                          to="/user-registration"
                          state={{ selectedPlan: item }}
                          className="btn btn-primary btn-skew mt-1 px-6 absolute bottom-4 left-4"
                        >
                          <span>Enroll</span>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="text-center">
              <button
                onClick={handlePrevSlide}
                disabled={isBeginning}
                className={`
                  bg-primary text-white p-2 rounded-full
                  ${
                    isBeginning
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-opacity-80'
                  }
                `}
              >
                <ChevronLeft size={24} />
              </button>

              <button
                onClick={handleNextSlide}
                disabled={isEnd}
                className={`
                  bg-primary text-white p-2 rounded-full
                  ${
                    isEnd
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-opacity-80'
                  }
                `}
              >
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Pricing
