import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IMAGES, SVGICON } from '../constants/theme'
import PageTitle from '../elements/PageTitle'
import NewsLetter from '../elements/NewsLetter'

const programs = [
  {
    title: 'Crossfit',
    image: IMAGES.Service101,
    description:
      'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
  },
  {
    title: 'Circuit Training',
    image: IMAGES.Service102,
    description:
      'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
  },

  {
    title: 'Aerobics',
    image: IMAGES.Service104,
    description:
      'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
  },
  {
    title: 'Zumba',
    image: IMAGES.Service105,
    description:
      'Zumba is a high-energy, dance-based workout that combines Latin rhythms with cardio exercises to create a fun, calorie-burning fitness experience.',
  },

  {
    title: 'Weight Training',
    image: IMAGES.Service107,
    description:
      'Weight training involves lifting weights to build muscle mass, increase strength, and enhance overall physical performance.',
  },
  {
    title: 'TRX',
    image: IMAGES.Service108,
    description:
      'TRX is a versatile suspension training system that uses your body weight to build strength, flexibility, and stability.',
  },
  {
    title: 'Power Yoga',
    image: IMAGES.Service111,
    description:
      'Power Yoga is an intense, fast-paced form of yoga that combines strength, flexibility, and cardio for a full-body workout.',
  },
  {
    title: 'Pilates',
    image: IMAGES.Service112,
    description:
      'Pilates is a low-impact exercise system focused on improving core strength, flexibility, and overall body alignment.',
  },
  {
    title: 'Calisthenics',
    image: IMAGES.Service106,
    description:
      'Calisthenics is a form of strength training that uses your body weight to build muscle, improve flexibility, and enhance overall physical fitness.',
  },
  {
    title: 'Yoga',
    image: IMAGES.Service110,
    description:
      'Yoga combines physical postures, breathing techniques, and meditation to promote physical strength, flexibility, and mental well-being.',
  },
  {
    title: 'Functional Training',
    image: IMAGES.Service109,
    description:
      'Functional Training enhances your ability to perform daily activities by focusing on movements that improve strength, balance, and coordination.',
  },
  {
    title: 'Bollywood Dance',
    image: IMAGES.Service103,
    description:
      'Bollywood Fitness Dance combines energetic dance moves with vibrant music to create a fun, full-body workout that feels more like a party than exercise.',
  },
]

const Programs = () => {
  const [hover, setHover] = useState(0)
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Services" parentTitle="Services" />
        <section
          className="content-inner overflow-hidden"
          style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
        >
          <div className="container">
            <div className="section-head style-1 text-center">
              {/* <h4 className="sub-text text-muted">Client</h4> */}
              <h2 className="title">
                Our <span className="text-primary">Training Programs</span>
              </h2>
            </div>
            <div className="row">
              {programs.map((item, index) => (
                <div className="col-xl-3 col-md-6 m-b30" key={index}>
                  <div
                    className={`icon-bx-wraper style-7 box-hover ${
                      hover === index ? 'active' : ''
                    }`}
                    onMouseEnter={() => setHover(index)}
                  >
                    <div className="icon-bx m-b30">
                      <span className="icon-cell">
                        <img src={item.image} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h5 className="dz-title m-b10">
                        <Link to={'#'}>{item.title}</Link>
                      </h5>
                      <p className="m-b25">{item.description}</p>
                      <Link
                        to={'/services-details'}
                        className="btn btn-primary shadow-primary btn-skew"
                      >
                        <span>Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  )
}

export default Programs
