import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { Navigation, Pagination } from 'swiper'
import { IMAGES } from '../constants/theme'

const dataBlog = [
  {
    image: IMAGES.avatarlarge1,
    name: 'Arohi Singh',
    description:
      "Hands Down, Best Choice I've Made This Year By Enrolling Myself At Flex Fitness...!!!! Its Is A Fantastic Gym!! Have Quality Equipment, Access To Sauna & Roof Top Football Turf Are Icing On The Cake.",
  },
  {
    image: IMAGES.avatarlarge2,
    name: 'Rohan Agarwal',
    description:
      'An All Inclusive Gym Was Very Difficult To Find In Lucknow And Flex Fitness Certainly Proved Me Wrong. An Excellent Gym With All The Possible Equipments, Facilities And A Very Courteous Team Of Trainers.10 Stars To You Guys!',
  },
  {
    image: IMAGES.avatarlarge3,
    name: 'Himmat Singh',
    description:
      'A Gym Located In The Heart Of Vrindavan Yojna, With All The Essential Training Machines Along With Good Trainers. Keep Training Yourself At This Gym!! Highly Recommend',
  },
]

function ClientSlider() {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const paginationRef = React.useRef(null)
  return (
    <>
      <Swiper
        className="testimonial-swiper"
        slidesPerView={'auto'}
        spaceBetween={0}
        loop={true}
        speed={1500}
        navigation={{
          nextEl: '.btn-next',
          prevEl: '.btn-prev',
        }}
        pagination={{
          el: '.swiper-pagination',

          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '"> 0' + (index + 1) + '</span>'
            )
          },
        }}
        //autoplay= {{
        //    delay: 4500,
        //}}
        modules={[Navigation, Pagination]}
      >
        {dataBlog.map((item, ind) => (
          <SwiperSlide key={ind}>
            <div className="testimonial-1">
              <div className="testimonial-pic">
                <img src={item.image} alt="" />
              </div>
              <ul className="testimonial-rating">
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
              </ul>
              <div className="testimonial-info">
                <p className="testimonial-text">{item.description}</p>
                <h4 className="testimonial-name">{item.name}</h4>
                {/* <span className="testimonial-position text-primary">
                  Founder
                </span> */}
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="num-pagination">
          <div
            className="testimonial-button-prev btn-prev"
            ref={navigationPrevRef}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div className="swiper-pagination style-1" ref={paginationRef}></div>
          <div
            className="testimonial-button-next btn-next"
            ref={navigationNextRef}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </Swiper>
    </>
  )
}
export default ClientSlider
