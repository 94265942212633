import React from 'react'
import { Accordion } from 'react-bootstrap'
const accordBlog = [
  {
    content: [
      {
        title: 'Target Audience',
        description:
          "Our gym caters to individuals of all fitness levels, from beginners to advanced athletes. Whether you're looking to lose weight, build muscle, improve overall fitness, or train for specific goals, we have programs and equipment tailored to meet your unique needs. Our diverse range of classes and training options ensures that everyone can find their perfect fitness journey with us.",
      },
      {
        title: 'Equipment Used',
        description:
          'We pride ourselves on maintaining a state-of-the-art fitness facility with top-of-the-line equipment. Our gym features a comprehensive range of cardio machines, free weights, resistance training equipment, functional training areas, and specialized tools like TRX suspension trainers and kettlebells. We continuously update our equipment to provide the best possible training experience and support your fitness goals.',
      },
      {
        title: 'What facilities do you offer?',
        description:
          'Our gym offers a wide range of facilities to support your fitness journey. This includes multiple workout zones for different training styles, group fitness studios, dedicated areas for strength training, cardio spaces, and specialized rooms for activities like yoga and dance fitness. We also provide amenities such as a nutrition consultation area, recovery zones, and comfortable waiting spaces.',
      },
      {
        title: 'Do you provide lockers and showers?',
        description:
          'Yes, we offer modern, secure locker facilities and clean, well-maintained shower areas. Our locker rooms are spacious and include both open and private changing areas. Lockers are available for daily use, and we provide secure storage options for members. The shower facilities are equipped with hot water, clean towels, and toiletry essentials to ensure your post-workout comfort.',
      },
    ],
  },
  {
    content: [
      {
        title: 'Do you offer personal training?',
        description:
          "Absolutely! Our certified personal trainers are experts in creating customized fitness programs tailored to your specific goals, fitness level, and health conditions. Whether you're looking to lose weight, build muscle, improve athletic performance, or rehabilitate from an injury, our trainers provide one-on-one guidance, motivation, and professional support to help you achieve your fitness objectives.",
      },
      {
        title: 'Are we open on weekend?',
        description:
          'Yes, we are fully operational on weekends! Our weekend hours are designed to accommodate your busy schedule. We offer extended hours on Saturdays and Sundays, with a full range of classes, open gym access, and personal training sessions available. Our weekend schedule ensures that you can maintain your fitness routine without interruption, regardless of your weekday commitments.',
      },
      {
        title: 'How to enroll?',
        description:
          'Enrolling is simple and straightforward. You can sign up online through our website, visit our gym in person, or call our membership team. We offer various membership options including monthly, quarterly, and annual plans. New members can take advantage of a free initial consultation and trial session to help you understand our facilities and find the perfect fitness program for your needs.',
      },
    ],
  },
]
const AccordionElement = () => {
  return (
    <>
      <section className="content-inner-1 overflow-hidden">
        <div className="container">
          <div className="row">
            {accordBlog.map((data, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  <Accordion
                    className="dz-accordion dz-accordion-skew"
                    id="accordionFaq2"
                    defaultActiveKey={0}
                  >
                    {data.content.map((item, index) => (
                      <Accordion.Item
                        className="accordion-item"
                        key={index}
                        eventKey={index}
                      >
                        <Accordion.Header as="h2" id="headingOne1">
                          {item.title}
                          <span className="toggle-close"></span>
                        </Accordion.Header>
                        <div id="collapseOne1" className="accordion-collapse">
                          <Accordion.Body>
                            <p className="m-b0">{item.description}</p>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default AccordionElement
