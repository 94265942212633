import React from 'react'
import { IMAGES } from '../../constants/theme'

const PowerYoga = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Ignite Your Strength and Flexibility with Power Yoga!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service111} alt="" />
          </div>
          <p>
            Power Yoga is a vigorous, fitness-based approach to traditional
            yoga, designed to build strength, flexibility, and stamina while
            providing a cardiovascular workout. Unlike the more meditative forms
            of yoga, Power Yoga focuses on dynamic, flowing movements that link
            one pose to the next, often at a quicker pace. This makes it an
            excellent choice for those looking to combine the benefits of yoga
            with a more intense, athletic experience. Power Yoga sessions
            typically involve a series of challenging poses that engage multiple
            muscle groups, helping to tone your body and improve endurance. It
            also incorporates elements of balance and coordination, ensuring a
            well-rounded workout. For beginners, Power Yoga can be demanding,
            but it offers the opportunity to build strength and flexibility over
            time. It’s a practice that not only sculpts the body but also
            sharpens the mind by requiring focus and discipline throughout the
            session.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Full-Body Strength: </strong>Engages and strengthens major
              muscle groups, particularly the core, arms, and legs.
            </li>
            <li>
              <strong>Improved Flexibility: </strong>The dynamic movements and
              stretches increase flexibility and range of motion.
            </li>
            <li>
              <strong>Cardiovascular Fitness: </strong>The faster pace provides
              a cardio workout, enhancing heart health and endurance.
            </li>
            <li>
              <strong>Mental Focus: </strong>Power Yoga demands concentration
              and mental discipline, promoting clarity and stress relief..
            </li>
            <li>
              <strong>Increased Stamina: </strong>Regular practice builds
              endurance, allowing you to sustain physical activity for longer
              periods.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Warm-Up Thoroughly: </strong>Given the intensity of
                Power Yoga, start with a warm-up to prepare your muscles and
                joints.
              </li>
              <li>
                <strong>Focus on Form: </strong>Maintain proper alignment in
                each pose to avoid injury and maximize the benefits.
              </li>
              <li>
                <strong>Pace Yourself: </strong>If you’re new to Power Yoga,
                start with shorter sessions and gradually increase the duration
                and intensity.
              </li>
              <li>
                <strong>Stay Hydrated: </strong>Keep water nearby, as the high
                intensity can lead to significant sweating.
              </li>
              <li>
                <strong>Incorporate Rest Days: </strong>Allow your body time to
                recover between sessions, especially when starting out.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PowerYoga
