// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'

// const boxWrapper = [
//   {
//     image: IMAGES.boxlog1,
//     title: 'Right Nutrition',
//     description:
//       'Unlock your fitness potential with personalized nutrition plans. Fuel your body for optimal performance and sustainable results.',
//   },
//   {
//     image: IMAGES.boxlog2,
//     title: 'Health & Fitness',
//     description:
//       'Get expert guidance on creating a balanced fitness program. Improve your overall health and well-being.',
//   },
//   {
//     image: IMAGES.boxlog3,
//     title: 'Gym & Exercise',
//     description:
//       'Discover a wide range of gym equipment and exercise options tailored to your needs and preferences.',
//   },
//   {
//     image: IMAGES.boxlog4,
//     title: 'Health Motivation',
//     description:
//       'Find the motivation and accountability you need to stay on track. Our supportive community will help you reach your goals.',
//   },
// ]

// const FitnessIdeas = () => {
//   const [hoverBox, setHoverBox] = useState(0)
//   return (
//     <>
//       <div className="row">
//         {boxWrapper.map((item, index) => (
//           <div className="col-xl-3 col-md-6 m-b30" key={index}>
//             <div
//               className={`icon-bx-wraper style-1 box-hover ${
//                 index === hoverBox ? 'active' : ''
//               }`}
//               onMouseEnter={() => setHoverBox(index)}
//             >
//               <div className="icon-bx m-b30">
//                 <span className="icon-cell">
//                   <img src={item.image} alt="" />
//                 </span>
//               </div>
//               <div className="icon-content">
//                 <h5 className="dz-title m-b10">
//                   <Link to={'/services-details'}>{item.title}</Link>
//                 </h5>
//                 <p className="m-b25">{item.description}</p>
//                 <Link
//                   to={'/services-details'}
//                   className="btn btn-primary shadow-primary btn-skew"
//                 >
//                   <span>Read More</span>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </>
//   )
// }

// export default FitnessIdeas

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants/theme'

const boxWrapper = [
  {
    image: IMAGES.boxlog1,
    title: 'Right Nutrition',
    description:
      'Unlock your fitness potential with personalized nutrition plans. Fuel your body for optimal performance and sustainable results.',
  },
  {
    image: IMAGES.boxlog2,
    title: 'Health & Fitness',
    description:
      'Get expert guidance on creating a balanced fitness program. Improve your overall health and well-being.',
  },
  {
    image: IMAGES.boxlog3,
    title: 'Gym & Exercise',
    description:
      'Discover a wide range of gym equipment and exercise options tailored to your needs and preferences.',
  },
  {
    image: IMAGES.boxlog4,
    title: 'Health Motivation',
    description:
      'Find the motivation and accountability you need to stay on track. Our supportive community will help you reach your goals.',
  },
]

const FitnessIdeas = () => {
  const [hoverBox, setHoverBox] = useState(0)
  return (
    <div className="row">
      {boxWrapper.map((item, index) => (
        <div className="col-xl-3 col-md-6 mb-4 d-flex" key={index}>
          <div
            className={`icon-bx-wraper style-1 box-hover w-100 d-flex flex-column ${
              index === hoverBox ? 'active' : ''
            }`}
            onMouseEnter={() => setHoverBox(index)}
          >
            <div className="icon-bx m-b30">
              <span className="icon-cell">
                <img src={item.image} alt="" className="img-fluid" />
              </span>
            </div>
            <div className="icon-content d-flex flex-column flex-grow-1">
              <h5 className="dz-title m-b10">
                <Link to={'/services-details'}>{item.title}</Link>
              </h5>
              <p className="m-b25 flex-grow-1">{item.description}</p>
              <Link
                to={'/services-details'}
                className="btn btn-primary shadow-primary btn-skew mt-auto"
              >
                <span>Read More</span>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FitnessIdeas
