// import React from 'react'
// import { Link } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'
// import NewsLetter from '../elements/NewsLetter'
// import PageTitle from '../elements/PageTitle'

// const ServicesDetails = () => {
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Service Details" parentTitle="Services" />
//         <div
//           className="content-inner "
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="row">
//               <div className="col-xl-8 col-lg-7 order-lg-1">
//                 <div className="dz-media m-b30">
//                   <img src={IMAGES.bloglarg1} alt="" />
//                 </div>
//                 <div className="dz-content">
//                   <div className="m-b40">
//                     <h2 className="title m-b15">
//                       Providing world class services
//                     </h2>
//                     <p>
//                       We are an independent gym that is committed to working
//                       with you to gain the results you want. Whether your aim is
//                       to loose weight tone up build bulk or gain weight we can
//                       put together a gym programme or recommend the right
//                       classes for you to attend in our studios.
//                     </p>
//                     <p>
//                       We offer the best support and service you can imagine. Use
//                       our support forum if you have any questions and our team
//                       will respond. We have thousands of solved threads and a
//                       customer satisfaction of 97%. We do care that your site
//                       runs great!
//                     </p>
//                   </div>

//                   <div className="m-b40">
//                     <h4 className="m-b15">
//                       Learn About Fitness From These Mistakes
//                     </h4>
//                     <ul className="list-check-2 m-b30">
//                       <li>
//                         We are an independent gym that is committed to working
//                       </li>
//                       <li>
//                         Whether your aim is to loose weight tone up build bulk
//                         or gain weight
//                       </li>
//                       <li>
//                         We have thousands of solved threads and a customer
//                         satisfaction
//                       </li>
//                     </ul>
//                     <p>
//                       We offer the best support and service you can imagine. Use
//                       our support forum if you have any questions and our team
//                       will respond. We have thousands of solved threads and a
//                       customer satisfaction of 97%. We do care that your site
//                       runs great!
//                     </p>
//                   </div>
//                   <div className="row align-items-center">
//                     <div className="col-xl-6 m-b30">
//                       <h4 className="m-b10">Start Online Courses ?</h4>
//                       <p>
//                         We are an independent gym that is committed to working
//                         with you to gain the results you want.
//                       </p>
//                       <p className="m-b0">
//                         Whether your aim is to loose weight tone up build bulk
//                         or gain weight we can put together a gym programme or
//                         recommend the right classes for you to attend in our
//                         studios members benefit.
//                       </p>
//                     </div>
//                     <div className="col-xl-6 m-b30">
//                       <div className="dz-media">
//                         <img
//                           src={IMAGES.bloggrid1}
//                           className="img-cover"
//                           alt=""
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-xl-4 col-lg-5 m-b30">
//                 <aside className="side-bar left sticky-top">
//                   <div className="widget service_menu_nav">
//                     <ul>
//                       <li className="active">
//                         <Link to={'#'}>Motivation</Link>{' '}
//                       </li>
//                       <li>
//                         <Link to={'#'}>Workout</Link>
//                       </li>
//                       <li>
//                         <Link to={'#'}>Online Courses</Link>{' '}
//                       </li>
//                       <li>
//                         <Link to={'#'}>Fat Loss</Link>{' '}
//                       </li>
//                       <li>
//                         <Link to={'#'}>Perfect Diet</Link>{' '}
//                       </li>
//                       <li>
//                         <Link to={'#'}>Health Coach</Link>{' '}
//                       </li>
//                       <li>
//                         <Link to={'#'}>Weight Gain</Link>{' '}
//                       </li>
//                     </ul>
//                     <svg
//                       width="250"
//                       height="70"
//                       viewBox="0 0 250 70"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M0 38L250 0L210 70L0 38Z"
//                         fill="url(#paint0_linear_306_1296)"
//                       ></path>
//                       <defs>
//                         <linearGradient
//                           id="paint0_linear_306_1296"
//                           x1="118.877"
//                           y1="35.552"
//                           x2="250.365"
//                           y2="35.552"
//                           gradientUnits="userSpaceOnUse"
//                         >
//                           <stop offset="1" stopColor="var(--primary)"></stop>
//                         </linearGradient>
//                       </defs>
//                     </svg>
//                   </div>
//                   <div className="widget_contact ">
//                     <div className="widget-content">
//                       <div className="icon-bx">
//                         <i className="flaticon-contact-center"></i>
//                       </div>
//                       <h4>Do you need any help?</h4>
//                       <div className="phone-number">+91 12345 678 98</div>
//                       <h6 className="email">info@dexignzone.com</h6>
//                       <div className="link-btn">
//                         <Link
//                           to={'/contact-us'}
//                           className="btn btn-dark btn-skew"
//                         >
//                           <span>Contact Us</span>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </aside>
//               </div>
//             </div>
//           </div>
//         </div>
//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default ServicesDetails

// import React, { useEffect, useState } from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'
// import NewsLetter from '../elements/NewsLetter'
// import PageTitle from '../elements/PageTitle'
// import CrossFit from '../components/service-details/CrossFit'

// const programs = [
//   {
//     id: 'crossfit',
//     title: 'Crossfit',
//     image: IMAGES.Service101,
//     description:
//       'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
//     fullDescription: [
//       'CrossFit is a high-intensity fitness program that combines elements of weightlifting, cardio, and bodyweight exercises.',
//       'Our CrossFit classes are designed to challenge and transform your body through varied, functional movements performed at high intensity.',
//       'Participants can expect a comprehensive workout that improves strength, endurance, flexibility, and overall fitness.',
//     ],
//   },
//   {
//     id: 'circuit-training',
//     title: 'Circuit Training',
//     image: IMAGES.Service102,
//     description:
//       'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
//     fullDescription: [
//       'Circuit Training provides a full-body workout that keeps you moving and maximizes your time in the gym.',
//       'Each circuit consists of several exercise stations that target different muscle groups, ensuring a comprehensive fitness experience.',
//       'Our expert trainers design circuits that challenge both beginners and advanced fitness enthusiasts.',
//     ],
//   },
//   {
//     id: 'aerobics',
//     title: 'Aerobics',
//     image: IMAGES.Service104,
//     description:
//       'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
//     fullDescription: [
//       'Our Aerobics classes are designed to get your heart pumping and improve cardiovascular endurance.',
//       'With upbeat music and dynamic movements, these classes make fitness fun and engaging.',
//       'Suitable for all fitness levels, our Aerobics program helps you burn calories and improve overall health.',
//     ],
//   },
//   // Add other programs with unique ids
// ]

// const ServicesDetails = () => {
//   const { programId } = useParams()
//   const navigate = useNavigate()
//   const [selectedProgram, setSelectedProgram] = useState(null)

//   useEffect(() => {
//     // Find the program based on the URL parameter
//     const foundProgram = programs.find((p) => p.id === programId)

//     if (foundProgram) {
//       setSelectedProgram(foundProgram)
//     } else {
//       // Redirect to the first program if no match found
//       navigate(`/services/${programs[0].id}`)
//     }
//   }, [programId, navigate])

//   const handleSidebarClick = (programId) => {
//     navigate(`/services/${programId}`)
//   }

//   if (!selectedProgram) return null

//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Service Details" parentTitle="Services" />
//         <div
//           className="content-inner"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="row">
//               <div className="col-xl-8 col-lg-7 order-lg-1">
//                 <div className="dz-media m-b30">
//                   <img
//                     src={selectedProgram.image}
//                     alt={selectedProgram.title}
//                   />
//                 </div>
//                 <div className="dz-content">
//                   <div className="m-b40">
//                     <h2 className="title m-b15">
//                       {selectedProgram.title} Program
//                     </h2>
//                     {selectedProgram.fullDescription.map((paragraph, index) => (
//                       <p key={index}>{paragraph}</p>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <CrossFit />
//               <div className="col-xl-4 col-lg-5 m-b30">
//                 <aside className="side-bar left sticky-top">
//                   <div className="widget service_menu_nav">
//                     <ul>
//                       {programs.map((prog) => (
//                         <li
//                           key={prog.id}
//                           className={prog.id === programId ? 'active' : ''}
//                         >
//                           <Link
//                             to="#"
//                             onClick={() => handleSidebarClick(prog.id)}
//                           >
//                             {prog.title}
//                           </Link>
//                         </li>
//                       ))}
//                     </ul>
//                     <svg
//                       width="250"
//                       height="70"
//                       viewBox="0 0 250 70"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M0 38L250 0L210 70L0 38Z"
//                         fill="url(#paint0_linear_306_1296)"
//                       ></path>
//                       <defs>
//                         <linearGradient
//                           id="paint0_linear_306_1296"
//                           x1="118.877"
//                           y1="35.552"
//                           x2="250.365"
//                           y2="35.552"
//                           gradientUnits="userSpaceOnUse"
//                         >
//                           <stop offset="1" stopColor="var(--primary)"></stop>
//                         </linearGradient>
//                       </defs>
//                     </svg>
//                   </div>
//                   <div className="widget_contact ">
//                     <div className="widget-content">
//                       <div className="icon-bx">
//                         <i className="flaticon-contact-center"></i>
//                       </div>
//                       <h4>Do you need any help?</h4>
//                       <div className="phone-number">+91 12345 678 98</div>
//                       <h6 className="email">info@dexignzone.com</h6>
//                       <div className="link-btn">
//                         <Link
//                           to={'/contact-us'}
//                           className="btn btn-dark btn-skew"
//                         >
//                           <span>Contact Us</span>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </aside>
//               </div>
//             </div>
//           </div>
//         </div>
//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default ServicesDetails

// import React, { useEffect, useState } from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'
// import NewsLetter from '../elements/NewsLetter'
// import PageTitle from '../elements/PageTitle'
// import CrossFit from '../components/service-details/CrossFit'
// import CircuitTraining from '../components/service-details/CircuitTraining'
// import Aerobics from '../components/service-details/Aerobics'

// const menuItems = [
//   {
//     id: 'crossfit',
//     label: 'Crossfit',
//     content: <CrossFit />,
//   },
//   {
//     id: 'circuit-training',
//     label: 'Circuit Training',
//     content: <CircuitTraining />,
//   },
//   {
//     id: 'aerobics',
//     label: 'Aerobics',
//     content: <Aerobics />,
//   },
// ]

// const ServicesDetails = () => {
//   const { programId } = useParams()
//   const navigate = useNavigate()
//   const [selectedProgram, setSelectedProgram] = useState(null)
//   const [selectedMenuItem, setSelectedMenuItem] = useState('crossfit')

//   useEffect(() => {
//     // Find the program based on the URL parameter
//     const foundProgram = menuItems.find((p) => p.id === programId)

//     if (foundProgram) {
//       setSelectedProgram(foundProgram)
//     } else {
//       // Redirect to the first program if no match found
//       navigate(`/services/${menuItems[0].id}`)
//     }
//   }, [programId, navigate])

//   const selectedContent = menuItems.find(
//     (item) => item.id === selectedMenuItem
//   )?.content
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Service Details" parentTitle="Services" />
//         <div
//           className="content-inner "
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="row">
//               {/* <div className="col-xl-8 col-lg-7 order-lg-1">
//                 <div className="dz-media m-b30">
//                   <img src={IMAGES.bloglarg1} alt="" />
//                 </div>
//                 <div className="dz-content">
//                   <div className="m-b40">
//                     <h2 className="title m-b15">
//                       Providing world class services
//                     </h2>
//                     <p>
//                       We are an independent gym that is committed to working
//                       with you to gain the results you want. Whether your aim is
//                       to loose weight tone up build bulk or gain weight we can
//                       put together a gym programme or recommend the right
//                       classes for you to attend in our studios.
//                     </p>
//                     <p>
//                       We offer the best support and service you can imagine. Use
//                       our support forum if you have any questions and our team
//                       will respond. We have thousands of solved threads and a
//                       customer satisfaction of 97%. We do care that your site
//                       runs great!
//                     </p>
//                   </div>

//                   <div className="m-b40">
//                     <h4 className="m-b15">
//                       Learn About Fitness From These Mistakes
//                     </h4>
//                     <ul className="list-check-2 m-b30">
//                       <li>
//                         We are an independent gym that is committed to working
//                       </li>
//                       <li>
//                         Whether your aim is to loose weight tone up build bulk
//                         or gain weight
//                       </li>
//                       <li>
//                         We have thousands of solved threads and a customer
//                         satisfaction
//                       </li>
//                     </ul>
//                     <p>
//                       We offer the best support and service you can imagine. Use
//                       our support forum if you have any questions and our team
//                       will respond. We have thousands of solved threads and a
//                       customer satisfaction of 97%. We do care that your site
//                       runs great!
//                     </p>
//                   </div>
//                   <div className="row align-items-center">
//                     <div className="col-xl-6 m-b30">
//                       <h4 className="m-b10">Start Online Courses ?</h4>
//                       <p>
//                         We are an independent gym that is committed to working
//                         with you to gain the results you want.
//                       </p>
//                       <p className="m-b0">
//                         Whether your aim is to loose weight tone up build bulk
//                         or gain weight we can put together a gym programme or
//                         recommend the right classes for you to attend in our
//                         studios members benefit.
//                       </p>
//                     </div>
//                     <div className="col-xl-6 m-b30">
//                       <div className="dz-media">
//                         <img
//                           src={IMAGES.bloggrid1}
//                           className="img-cover"
//                           alt=""
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div> */}
//               {selectedContent}
//               <div className="col-xl-4 col-lg-5 m-b30">
//                 <aside className="side-bar left sticky-top">
//                   <div className="widget service_menu_nav">
//                     <ul>
//                       {menuItems.map((item, index) => (
//                         <li
//                           key={item.id}
//                           onClick={() => setSelectedMenuItem(item.id)}
//                           // className={index === 0 ? 'active' : ''}
//                           className={
//                             selectedMenuItem === item.id ? 'active' : ''
//                           }
//                         >
//                           <Link to={'#'}>{item.label}</Link>{' '}
//                         </li>
//                       ))}
//                     </ul>
//                     <svg
//                       width="250"
//                       height="70"
//                       viewBox="0 0 250 70"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M0 38L250 0L210 70L0 38Z"
//                         fill="url(#paint0_linear_306_1296)"
//                       ></path>
//                       <defs>
//                         <linearGradient
//                           id="paint0_linear_306_1296"
//                           x1="118.877"
//                           y1="35.552"
//                           x2="250.365"
//                           y2="35.552"
//                           gradientUnits="userSpaceOnUse"
//                         >
//                           <stop offset="1" stopColor="var(--primary)"></stop>
//                         </linearGradient>
//                       </defs>
//                     </svg>
//                   </div>
//                   <div className="widget_contact ">
//                     <div className="widget-content">
//                       <div className="icon-bx">
//                         <i className="flaticon-contact-center"></i>
//                       </div>
//                       <h4>Do you need any help?</h4>
//                       <div className="phone-number">+91 12345 678 98</div>
//                       <h6 className="email">info@dexignzone.com</h6>
//                       <div className="link-btn">
//                         <Link
//                           to={'/contact-us'}
//                           className="btn btn-dark btn-skew"
//                         >
//                           <span>Contact Us</span>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </aside>
//               </div>
//             </div>
//           </div>
//         </div>
//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default ServicesDetails

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IMAGES } from '../constants/theme'
import NewsLetter from '../elements/NewsLetter'
import PageTitle from '../elements/PageTitle'
import CrossFit from '../components/service-details/CrossFit'
import CircuitTraining from '../components/service-details/CircuitTraining'
import Aerobics from '../components/service-details/Aerobics'
import { Accordion } from 'react-bootstrap'
import AccordionElement from '../components/AccordionElement'
import Zumba from '../components/service-details/Zumba'
import WeightTraining from '../components/service-details/WeightTraining'
import TRX from '../components/service-details/TRX'
import PowerYoga from '../components/service-details/PowerYoga'
import Pilates from '../components/service-details/Pilates'
import Calisthenics from '../components/service-details/Calisthenics'
import Yoga from '../components/service-details/Yoga'
import FunctionalTraining from '../components/service-details/FunctionalTraining'
import BollywoodDance from '../components/service-details/BollywoodDance'

const menuItems = [
  {
    id: 'crossfit',
    label: 'Crossfit',
    content: <CrossFit />,
  },
  {
    id: 'circuit-training',
    label: 'Circuit Training',
    content: <CircuitTraining />,
  },
  {
    id: 'aerobics',
    label: 'Aerobics',
    content: <Aerobics />,
  },
  {
    id: 'zumba',
    label: 'Zumba',
    content: <Zumba />,
  },
  {
    id: 'weight-training',
    label: 'Weight Training',
    content: <WeightTraining />,
  },
  {
    id: 'trx',
    label: 'TRX',
    content: <TRX />,
  },
  {
    id: 'power-yoga',
    label: 'Power Yoga',
    content: <PowerYoga />,
  },
  {
    id: 'pilates',
    label: 'Pilates',
    content: <Pilates />,
  },
  {
    id: 'calisthenics',
    label: 'Calisthenics',
    content: <Calisthenics />,
  },
  {
    id: 'yoga',
    label: 'Yoga',
    content: <Yoga />,
  },
  {
    id: 'functional-training',
    label: 'Functional Training',
    content: <FunctionalTraining />,
  },
  {
    id: 'bollywood-dance',
    label: 'Bollywood Dance',
    content: <BollywoodDance />,
  },
]

const ServicesDetails = () => {
  const { serviceId } = useParams()
  // console.log('serviceId', serviceId)
  const navigate = useNavigate()
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    serviceId || menuItems[0].id
  )

  useEffect(() => {
    const foundProgram = menuItems.find((p) => p.id === serviceId)

    if (!foundProgram) {
      navigate(`/services/${menuItems[0].id}`)
    } else {
      setSelectedMenuItem(serviceId)
    }
  }, [serviceId, navigate])

  useEffect(() => {
    navigate(`/services/${selectedMenuItem}`)
  }, [selectedMenuItem, navigate])

  const selectedContent = menuItems.find(
    (item) => item.id === selectedMenuItem
  )?.content

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Service Details" parentTitle="Services" />
        <div
          className="content-inner"
          style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
        >
          <div className="container">
            <div className="row">
              {selectedContent}
              <div className="col-xl-4 col-lg-5 m-b30">
                <aside className="side-bar left sticky-top">
                  <div className="widget service_menu_nav">
                    <ul>
                      {menuItems.map((item) => (
                        <li
                          key={item.id}
                          onClick={() => setSelectedMenuItem(item.id)}
                          className={
                            selectedMenuItem === item.id ? 'active' : ''
                          }
                        >
                          <Link to="#">{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                    <svg
                      width="250"
                      height="70"
                      viewBox="0 0 250 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 38L250 0L210 70L0 38Z"
                        fill="url(#paint0_linear_306_1296)"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_306_1296"
                          x1="118.877"
                          y1="35.552"
                          x2="250.365"
                          y2="35.552"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="1" stopColor="var(--primary)"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="widget_contact ">
                    <div className="widget-content">
                      <div className="icon-bx">
                        <i className="flaticon-contact-center"></i>
                      </div>
                      <h4>Do you need any help?</h4>
                      <div className="phone-number">+91 12345 678 98</div>
                      <h6 className="email">info@dexignzone.com</h6>
                      <div className="link-btn">
                        <Link
                          to={'/contact-us'}
                          className="btn btn-dark btn-skew"
                        >
                          <span>Contact Us</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/* <Accordion /> */}
            </div>
            {/* Accordion now placed after the row */}
            <div className="row mt-0">
              <div className="col-12">
                <AccordionElement />
              </div>
            </div>
          </div>
        </div>
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  )
}

export default ServicesDetails
