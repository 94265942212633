// import React, { useState } from 'react'
// import { IMAGES, SVGICON } from '../constants/theme'
// import PageTitle from '../elements/PageTitle'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Link } from 'react-router-dom'
// import { Autoplay } from 'swiper'
// import { Check } from 'lucide-react'

// const carousel = [
//   {
//     planTitle: 'STARTER',
//     price: '4999',
//     duration: '1 Month',
//     description: [
//       'Perfect for beginners or those wanting to try out our facilities',
//       'Access to all gym equipment',
//       'Free Wi-Fi and locker facilities',
//     ],
//   },
//   {
//     planTitle: 'Enthusiast',
//     price: '9999',
//     duration: '3 Months',
//     description: [
//       'deal for short-term fitness goals.',
//       'Access to all gym equipment.',
//       'Free Wi-Fi and locker facilities.',
//       'Free nutrition consultation.',
//     ],
//   },
//   {
//     planTitle: 'Builder',
//     price: '14999',
//     duration: '6 Months',
//     description: [
//       'Designed for dedicated fitness enthusiasts.',
//       'Access to all gym equipment.',
//       'Free Wi-Fi and locker facilities.',
//       'Free nutrition consultation.',
//     ],
//   },
//   {
//     planTitle: 'Ultimate',
//     price: '19999',
//     duration: '12 Months',
//     description: [
//       'Best value for long-term fitness commitment.',
//       'Access to all gym equipment.',
//       'Free Wi-Fi and locker facilities.',
//       'Free nutrition consultation.',
//     ],
//   },
// ]

// const MemberShip = () => {
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Membership Plans" parentTitle="Membership" />
//         <section className="content-inner overflow-hidden">
//           <div className="container">
//             <div className="section-head style-1 text-center">
//               <h2 className="title">
//                 Plans and <span className="text-primary">Pricing</span>
//               </h2>
//             </div>

//             <div>
//               <div className="swiper blog-slider-2">
//                 <Swiper
//                   className="swiper-wrapper"
//                   slidesPerView={3}
//                   speed={1800}
//                   spaceBetween={30}
//                   modules={[Autoplay]}
//                   autoplay={{ delay: 1500 }}
//                   breakpoints={{
//                     1275: { slidesPerView: 3 },
//                     991: { slidesPerView: 2 },
//                     240: { slidesPerView: 1 },
//                   }}
//                 >
//                   {carousel.map((item, ind) => (
//                     <SwiperSlide className="swiper-slide" key={ind}>
//                       <div
//                         className="dz-card style-2 wow fadeInUp"
//                         style={{ backgroundImage: `url(${item.bgImg})` }}
//                       >
//                         <div className="post-date">
//                           <span className="date">₹{item.price}/-</span>
//                           <span className="months">For {item.duration}</span>
//                         </div>
//                         <div className="dz-info">
//                           <h2 className="dz-title">
//                             <Link to="/blog-details">{item.planTitle}</Link>
//                           </h2>
//                           <div className="mt-4">
//                             {item.description.map((point, index) => (
//                               <div
//                                 key={index}
//                                 className="flex items-center mb-3 bg-gray-100 rounded-lg"
//                               >
//                                 <Check
//                                   className="mr-3 px-1 text-primary"
//                                   size={20}
//                                 />
//                                 <span className="text-gray-700">{point}</span>
//                               </div>
//                             ))}
//                           </div>
//                           <Link
//                             to="/blog-details"
//                             className="btn rounded-0 btn-primary btn-skew mt-4"
//                           >
//                             <span>Read More</span>
//                           </Link>
//                         </div>
//                       </div>
//                     </SwiperSlide>
//                   ))}
//                 </Swiper>
//               </div>
//               <div className="text-center m-t50">
//                 <Link
//                   to="/blog-list-sidebar"
//                   className="btn btn-secondary btn-skew"
//                 >
//                   <span>View All Feed</span>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default MemberShip

import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Check, ChevronLeft, ChevronRight } from 'lucide-react'
import 'swiper/css'
import PageTitle from '../elements/PageTitle'

const carousel = [
  {
    planTitle: 'STARTER',
    price: '4999',
    duration: '1 Month',
    description: [
      'Perfect for beginners or those wanting to try out our facilities',
      'Access to all gym equipment',
      'Free Wi-Fi and locker facilities',
    ],
  },
  {
    planTitle: 'Enthusiast',
    price: '9999',
    duration: '3 Months',
    description: [
      'Deal for short-term fitness goals.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
  {
    planTitle: 'Builder',
    price: '14999',
    duration: '6 Months',
    description: [
      'Designed for dedicated fitness enthusiasts.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
  {
    planTitle: 'Ultimate',
    price: '19999',
    duration: '12 Months',
    description: [
      'Best value for long-term fitness commitment.',
      'Access to all gym equipment.',
      'Free Wi-Fi and locker facilities.',
      'Free nutrition consultation.',
    ],
  },
]

const MemberShip = () => {
  const navigate = useNavigate()
  const swiperRef = useRef(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Membership" parentTitle="" />
        <section className="content-inner overflow-hidden">
          <div className="container">
            <div className="section-head style-1 text-center mb-8">
              <h2 className="title">
                Plans and <span className="text-primary">Pricing</span>
              </h2>
            </div>

            <div className="relative">
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
                onSlideChange={handleSlideChange}
                slidesPerView={1}
                spaceBetween={30}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="relative"
              >
                {carousel.map((item, ind) => (
                  <SwiperSlide key={ind} className="h-auto">
                    <div className="dz-card style-3 h-full relative border border-3 p-4 rounded-lg">
                      <div className="mb-4 text">
                        <h4>₹{item.price}/-</h4>
                        <span>For {item.duration}</span>{' '}
                      </div>
                      <div className="dz-info">
                        <h2 className="dz-title mb-4 text-xl font-semibold">
                          <Link to="/blog-details">{item.planTitle}</Link>
                        </h2>
                        <div className="mb-4">
                          {item.description.map((point, index) => (
                            <div
                              key={index}
                              className="flex items-center bg-gray-100 rounded-lg p-2"
                            >
                              <Check
                                className="mr-3 px-1 text-primary"
                                size={20}
                              />
                              <span className="text-gray-700 text-sm">
                                {point}
                              </span>
                            </div>
                          ))}
                        </div>
                        <Link
                          to="/user-registration"
                          state={{ selectedPlan: item }}
                          className="btn btn-primary btn-skew mt-4 absolute bottom-4 left-4"
                        >
                          <span>Enroll</span>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="text-center">
              <button
                onClick={handlePrevSlide}
                disabled={isBeginning}
                className={`
                  bg-primary text-white p-2 rounded-full 
                  ${
                    isBeginning
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-opacity-80'
                  }
                `}
              >
                <ChevronLeft size={24} />
              </button>

              <button
                onClick={handleNextSlide}
                disabled={isEnd}
                className={`
                  bg-primary text-white p-2 rounded-full 
                  ${
                    isEnd
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-opacity-80'
                  }
                `}
              >
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default MemberShip
