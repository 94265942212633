import { IMAGES } from './theme'

export const transformation = [
  {
    img: IMAGES.portfolioPic101,
    startWeight: 250,
    endWeight: 180,
    duration: '12 months',
    name: 'John D.',
  },
  {
    img: IMAGES.portfolioPic102,
    startWeight: 220,
    endWeight: 165,
    duration: '9 months',
    name: 'Sarah M.',
  },
  {
    img: IMAGES.portfolioPic103,
    startWeight: 275,
    endWeight: 195,
    duration: '15 months',
    name: 'Mike R.',
  },
  {
    img: IMAGES.portfolioPic104,
    startWeight: 240,
    endWeight: 175,
    duration: '10 months',
    name: 'Emily K.',
  },
  {
    img: IMAGES.portfolioPic9,
    startWeight: 260,
    endWeight: 190,
    duration: '11 months',
    name: 'David L.',
  },
  {
    img: IMAGES.portfolioPic10,
    startWeight: 230,
    endWeight: 170,
    duration: '8 months',
    name: 'Lisa W.',
  },
]
