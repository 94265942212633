import { IMAGES } from './theme'

export const team = [
  { img: IMAGES.team1, title: 'KRISHNA PRAKASH', role: 'Trainer' },
  { img: IMAGES.team2, title: 'RASHID KHAN', role: 'Trainer' },
  { img: IMAGES.team3, title: 'ANIKET PATIL', role: 'Trainer' },
  { img: IMAGES.team4, title: 'ABHISHEK PATIL', role: 'Trainer' },
  { img: IMAGES.team5, title: 'ROHAN DHARNE', role: 'Trainer' },
  { img: IMAGES.team6, title: 'NAGRAJ KUMAR', role: 'Trainer' },
]
