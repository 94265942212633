import React from 'react'
import { IMAGES } from '../../constants/theme'

const Pilates = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Strengthen Your Core, Transform Your Body with Pilates!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service112} alt="" />
          </div>
          <p>
            Pilates is a versatile and low-impact exercise method that
            emphasizes core strength, flexibility, and balanced muscle
            development. Developed by Joseph Pilates in the early 20th century,
            this practice involves a series of controlled movements that target
            the deep muscles of the abdomen, back, and pelvis, often referred to
            as the &quot;powerhouse&quot;. Pilates exercises can be performed on
            a mat or specialized equipment like the Reformer, and are designed
            to improve posture, enhance body awareness, and increase overall
            physical stability. The slow, deliberate movements of Pilates
            require concentration and precision, making it not only a physical
            workout but also a mental one. Pilates is suitable for people of all
            fitness levels, whether you&apos;re recovering from an injury,
            looking to enhance your athletic performance, or simply aiming to
            improve your overall fitness. It’s particularly beneficial for those
            who want to develop a strong, toned core without the impact or
            strain associated with other forms of exercise.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Core Strength: </strong>Pilates specifically targets the
              core muscles, helping to build a strong, stable center.
            </li>
            <li>
              <strong>Improved Posture: </strong>The emphasis on alignment and
              balance improves overall posture and reduces the risk of injury.
            </li>
            <li>
              <strong>Enhanced Flexibility: </strong>The controlled stretching
              movements in Pilates increase flexibility and joint mobility.
            </li>
            <li>
              <strong>Full-Body Toning: </strong>Pilates engages multiple muscle
              groups, leading to a more toned and balanced physique.
            </li>
            <li>
              <strong>Stress Relief: </strong>The focus on controlled breathing
              and mindful movement promotes relaxation and reduces stress.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start with Basics: </strong>If you’re new to Pilates,
                begin with mat exercises to build a strong foundation before
                progressing to equipment-based routines.
              </li>
              <li>
                <strong>Focus on Breathing: </strong>Proper breathing is
                essential in Pilates, so ensure you’re coordinating your breath
                with your movements.
              </li>
              <li>
                <strong>Engage Your Core: </strong>Always keep your core muscles
                engaged throughout the exercises to maximize the benefits.
              </li>
              <li>
                <strong>Wear Comfortable Clothing: </strong>Choose fitted,
                flexible clothing that allows for a full range of motion without
                restricting movement.
              </li>
              <li>
                <strong>Consistency is Key: </strong>Regular practice will yield
                the best results, so incorporate Pilates into your weekly
                routine to see improvements in strength, flexibility, and
                posture.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pilates
