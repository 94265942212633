import React from 'react'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants/theme'
import NewsLetter from '../elements/NewsLetter'
import PageTitle from '../elements/PageTitle'

const mediaBlog = [
  { images: IMAGES.team1, title: 'KRISHNA PRAKASH', role: 'Trainer' },
  { images: IMAGES.team2, title: 'RASHID KHAN', role: 'Trainer' },
  { images: IMAGES.team3, title: 'ANIKET PATIL', role: 'Trainer' },
  //   { images: IMAGES.team4, title: 'ELIJAH' },
  //   { images: IMAGES.team5, title: 'JAMES' },
  //   { images: IMAGES.team6, title: 'AMELIA' },
]

const OurTeam = () => {
  return (
    <>
      <div className="page-content bg-white">
        {/* <PageTitle parentTitle="Pages" activePage="Our Team" /> */}
        <section>
          <div className="container">
            <div className="row ">
              {mediaBlog.map((item, index) => (
                <div className="col-lg-4 col-sm-6 m-b30" key={index}>
                  <div className="dz-team style-1">
                    <div className="dz-media">
                      <Link to={'#'}>
                        <img src={item.images} alt="" />
                      </Link>
                      <ul className="team-social">
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.facebook.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_blank"
                            to="https://twitter.com/?lang=en"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.instagram.com/?hl=en"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="dz-content ">
                      <h4 className="dz-name">{item.title}</h4>
                      <span className="dz-position">{item.role}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <div className="text-center">
        <Link to="/team" className="btn btn-secondary  btn-skew">
          <span>View All</span>
        </Link>
      </div>
    </>
  )
}

export default OurTeam
