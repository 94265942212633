import React from 'react'
import { IMAGES } from '../../constants/theme'

const BollywoodDance = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Dance Your Way to Fitness with Bollywood Beats!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service103} alt="" />
          </div>
          <p>
            Bollywood Fitness Dance is an exhilarating workout that blends
            traditional and modern Indian dance styles with upbeat Bollywood
            music. It’s a dynamic and fun way to burn calories, improve
            cardiovascular health, and boost your mood. The dance routines are
            choreographed to popular Bollywood songs, making the workout feel
            like a dance party. Each session involves a mix of high-energy dance
            moves that engage your entire body, helping to tone muscles,
            increase flexibility, and enhance coordination. Whether you’re new
            to dancing or a seasoned dancer, Bollywood Fitness Dance is
            accessible to everyone, offering modifications for different fitness
            levels. It&apos;s not just about the physical benefits—this workout
            also allows you to express yourself creatively and relieve stress,
            all while enjoying the infectious energy of Bollywood music.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Fun Full-Body Workout: </strong>Engages all muscle groups
              while keeping you entertained with energetic dance routines.
            </li>
            <li>
              <strong>Cardiovascular Health: </strong>The high-energy movements
              provide an excellent cardio workout, improving heart health.
            </li>
            <li>
              <strong>Stress Relief: </strong>Dancing to lively music helps
              release endorphins, reducing stress and boosting your mood.
            </li>
            <li>
              <strong>Improved Coordination: </strong>Regular practice enhances
              your rhythm, balance, and overall coordination.
            </li>
            <li>
              <strong>Cultural Experience: </strong>Enjoy a cultural journey
              through Bollywood dance, adding diversity to your fitness routine.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Wear Comfortable Clothing: </strong>Choose breathable,
                flexible attire that allows for easy movement.
              </li>
              <li>
                <strong>Stay Hydrated: </strong>Keep water nearby to stay
                hydrated, especially during high-energy sessions.
              </li>
              <li>
                <strong>Pace Yourself: </strong>Start with basic moves and
                gradually learn more complex routines as you gain confidence.
              </li>
              <li>
                <strong>Focus on Enjoyment: </strong>Don’t stress about getting
                every move right; the goal is to have fun and get moving.
              </li>
              <li>
                <strong>Join with Friends: </strong>Enhance the experience by
                bringing friends along to share in the fun and motivation.
              </li>
            </ul>
          </div>
          {/* <div className="col-xl-6 m-b30">
              <div className="dz-media">
                <img src={IMAGES.bloggrid1} className="img-cover" alt="" />
              </div>
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default BollywoodDance
