// import React, { useRef } from 'react'
// import { IMAGES } from '../constants/theme'
// import { Swiper, SwiperSlide } from 'swiper/react'

// // const carousel = [
// //   { img: IMAGES.portfolioPic8 },
// //   { img: IMAGES.portfolioPic9 },
// //   { img: IMAGES.portfolioPic10 },
// //   { img: IMAGES.portfolioPic8 },
// //   { img: IMAGES.portfolioPic9 },
// //   { img: IMAGES.portfolioPic10 },
// // ]

// const carousel = [
//   { img: IMAGES.portfolioPic101 },
//   { img: IMAGES.portfolioPic102 },
//   { img: IMAGES.portfolioPic103 },
//   { img: IMAGES.portfolioPic104 },
//   { img: IMAGES.portfolioPic9 },
//   { img: IMAGES.portfolioPic10 },
// ]
// const Portfolio = () => {
//   const swiperRef = useRef(null)
//   return (
//     <>
//       <div className="swiper portfolio-slider-2">
//         <div className="section-head style-1 text-center m-0">
//           {/* <span className="sub-title">ABOUT US</span> */}
//           <h2 className="title">
//             <span>Transformations</span>
//           </h2>
//         </div>
//         <Swiper
//           slidesPerView={4}
//           spaceBetween={20}
//           ref={swiperRef}
//           speed={1500}
//           breakpoints={{
//             1200: { slidesPerView: 4 },
//             991: { slidesPerView: 3 },
//             775: { slidesPerView: 2 },
//             240: { slidesPerView: 1 },
//           }}
//           className="swiper-wrapper"
//         >
//           {carousel.map((item, ind) => (
//             <SwiperSlide className="swiper-slide" key={ind}>
//               <div className="image-tooltip-effect dz-box style-3 wow fadeInUp">
//                 <div className="dz-media">
//                   <img src={item.img} alt="" />
//                 </div>
//                 <div className="dz-info">
//                   <h4 className="title m-b10">Yearly Fitness</h4>
//                   <h6 className="sub-title text-primary m-b0">$150000</h6>
//                 </div>
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//         <div
//           onClick={() => {
//             swiperRef.current.swiper.slidePrev()
//           }}
//           className="portfolio-2-button-prev btn-prev"
//         >
//           <i className="fas fa-chevron-left"></i>
//         </div>
//         <div
//           onClick={() => {
//             swiperRef.current.swiper.slideNext()
//           }}
//           className="portfolio-2-button-next btn-next"
//         >
//           <i className="fas fa-chevron-right"></i>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Portfolio

import React, { useRef } from 'react'
import { IMAGES } from '../constants/theme'
import { Swiper, SwiperSlide } from 'swiper/react'

const carousel = [
  {
    img: IMAGES.portfolioPic101,
    startWeight: 250,
    endWeight: 180,
    duration: '12 months',
    name: 'John D.',
  },
  {
    img: IMAGES.portfolioPic102,
    startWeight: 220,
    endWeight: 165,
    duration: '9 months',
    name: 'Sarah M.',
  },
  {
    img: IMAGES.portfolioPic103,
    startWeight: 275,
    endWeight: 195,
    duration: '15 months',
    name: 'Mike R.',
  },
  {
    img: IMAGES.portfolioPic104,
    startWeight: 240,
    endWeight: 175,
    duration: '10 months',
    name: 'Emily K.',
  },
  {
    img: IMAGES.portfolioPic101,
    startWeight: 250,
    endWeight: 180,
    duration: '12 months',
    name: 'John D.',
  },
  {
    img: IMAGES.portfolioPic102,
    startWeight: 220,
    endWeight: 165,
    duration: '9 months',
    name: 'Sarah M.',
  },
  {
    img: IMAGES.portfolioPic103,
    startWeight: 275,
    endWeight: 195,
    duration: '15 months',
    name: 'Mike R.',
  },
  {
    img: IMAGES.portfolioPic104,
    startWeight: 240,
    endWeight: 175,
    duration: '10 months',
    name: 'Emily K.',
  },
  // {
  //   img: IMAGES.portfolioPic9,
  //   startWeight: 260,
  //   endWeight: 190,
  //   duration: '11 months',
  //   name: 'David L.',
  // },
  // {
  //   img: IMAGES.portfolioPic10,
  //   startWeight: 230,
  //   endWeight: 170,
  //   duration: '8 months',
  //   name: 'Lisa W.',
  // },
]

const Portfolio = () => {
  const swiperRef = useRef(null)
  return (
    <>
      <div className="swiper portfolio-slider-2">
        <div className="section-head style-1 text-center m-0">
          <h2 className="title">
            <span>Transformations</span>
          </h2>
        </div>
        <Swiper
          slidesPerView={4}
          spaceBetween={20}
          ref={swiperRef}
          speed={1500}
          breakpoints={{
            1200: { slidesPerView: 4 },
            991: { slidesPerView: 3 },
            775: { slidesPerView: 2 },
            240: { slidesPerView: 1 },
          }}
          className="swiper-wrapper"
        >
          {carousel.map((item, ind) => (
            <SwiperSlide className="swiper-slide" key={ind}>
              <div className="image-tooltip-effect dz-box style-3 wow fadeInUp">
                <div className="dz-media">
                  <img src={item.img} alt={`${item.name} transformation`} />
                </div>
                <div className="dz-info">
                  <h4 className="title m-b10">
                    {item.name}
                    {"'"}s Transformation
                  </h4>
                  <div className="transformation-details text-primary">
                    <p className="m-b5">
                      <strong>Start Weight:</strong> {item.startWeight} lbs
                    </p>
                    <p className="m-b5">
                      <strong>End Weight:</strong> {item.endWeight} lbs
                    </p>
                    <p className="m-b5">
                      <strong>Duration:</strong> {item.duration}
                    </p>
                    <p className="m-b0">
                      <strong>Weight Loss:</strong>{' '}
                      {item.startWeight - item.endWeight} lbs
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          onClick={() => {
            swiperRef.current.swiper.slidePrev()
          }}
          className="portfolio-2-button-prev btn-prev"
        >
          <i className="fas fa-chevron-left"></i>
        </div>
        <div
          onClick={() => {
            swiperRef.current.swiper.slideNext()
          }}
          className="portfolio-2-button-next btn-next"
        >
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    </>
  )
}

export default Portfolio
