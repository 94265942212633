import React from 'react'
import { IMAGES } from '../../constants/theme'

const CrossFit = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Unleash Your Inner Athlete with CrossFit: A Fitness Revolution!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service101} alt="" />
          </div>
          <p>
            CrossFit is a high-intensity fitness program designed to build
            strength, endurance, and agility through a mix of functional
            movements. These workouts, known as WODs (Workouts of the Day), vary
            daily and combine elements from various sports and exercise
            disciplines, including weightlifting, cardio, and gymnastics.
            Whether you{"'"}re lifting, jumping, or sprinting, CrossFit keeps
            your body guessing and your mind engaged.
          </p>
          <p>
            It{"'"}s perfect for anyone looking to break free from monotonous
            routines and truly challenge themselves. With a strong community
            spirit, CrossFit encourages camaraderie and support, making every
            workout a shared experience. For beginners, CrossFit might seem
            intense, but it{"'"}s scalable, meaning you can adjust the exercises
            to match your fitness level while gradually building up your
            strength and endurance.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Total Body Conditioning: </strong>CrossFit targets all
              muscle groups, enhancing overall strength and fitness.
            </li>
            <li>
              <strong>Improved Cardiovascular Health: </strong>High-intensity
              workouts boost heart health and endurance.
            </li>
            <li>
              <strong>Increased Flexibility: </strong>The varied movements in
              CrossFit improve flexibility and coordination.
            </li>
            <li>
              <strong>Enhanced Mental Toughness: </strong>Pushing through
              challenging WODs builds resilience and mental strength.
            </li>
            <li>
              <strong>Strong Community Support: </strong>CrossFit fosters a
              sense of belonging and motivation within its community.
            </li>
          </ul>
        </div>
        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start Slow: </strong>If you’re new to CrossFit, ease
                into it by scaling the workouts to match your fitness level.
              </li>
              <li>
                <strong>Focus on Form: </strong>Prioritize proper technique to
                avoid injury, especially during weightlifting exercises.
              </li>
              <li>
                <strong>Stay Consistent: </strong>CrossFit yields the best
                results with regular attendance, so commit to your workout
                schedule.
              </li>
              <li>
                <strong>Listen to Your Body: </strong>Push your limits, but know
                when to rest to prevent burnout or injury.
              </li>
              <li>
                <strong>Engage with the Community: </strong>Take advantage of
                the supportive environment by interacting with fellow
                CrossFitters and trainers.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CrossFit
