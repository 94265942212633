import React from 'react'
import { IMAGES } from '../../constants/theme'

const FunctionalTraining = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Elevate Everyday Strength with Functional Training!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service109} alt="" />
          </div>
          <p>
            Functional Training is a fitness approach that focuses on exercises
            designed to improve your ability to perform everyday activities with
            ease. Unlike traditional weightlifting, which often isolates
            specific muscles, functional training emphasizes movements that
            engage multiple muscle groups simultaneously. These exercises mimic
            the natural movements you make in daily life—such as lifting,
            bending, pushing, and pulling—helping to improve your overall
            functional strength, balance, and coordination. Functional Training
            is highly adaptable and can be tailored to meet the specific needs
            of any individual, whether you&apos;re an athlete looking to enhance
            performance or someone aiming to make everyday tasks easier and
            reduce the risk of injury. By incorporating tools like kettlebells,
            resistance bands, and medicine balls, functional training challenges
            your body in dynamic ways, making it a versatile and effective
            workout for all fitness levels.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Enhanced Daily Performance: </strong>Improves your ability
              to perform everyday tasks with greater ease and efficiency.
            </li>
            <li>
              <strong>Injury Prevention: </strong>Strengthens muscles and joints
              in ways that reduce the risk of injury during daily activities.
            </li>
            <li>
              <strong>Full-Body Engagement: </strong>Works multiple muscle
              groups at once, promoting balanced strength and coordination.
            </li>
            <li>
              <strong>Improved Balance and Stability: </strong>Exercises target
              core strength and stability, enhancing your overall balance.
            </li>
            <li>
              <strong>Increased Flexibility: </strong>Dynamic movements improve
              flexibility and range of motion, benefiting overall mobility.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Focus on Movement Patterns: </strong>Prioritize
                exercises that mimic real-life movements, such as squats,
                lunges, and lifts.
              </li>
              <li>
                <strong>Start with Bodyweight: </strong>Begin with bodyweight
                exercises to master the basics before adding resistance.
              </li>
              <li>
                <strong>Incorporate Variety: </strong>Use a range of equipment
                and exercises to keep workouts engaging and challenging.
              </li>
              <li>
                <strong>Warm-Up Properly: </strong>Prepare your body with a
                dynamic warm-up to reduce the risk of injury.
              </li>
              <li>
                <strong>Monitor Progress: </strong>Track your improvements in
                daily activities and adjust your training routine accordingly to
                continue progressing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunctionalTraining
