import React from 'react'
import { IMAGES } from '../../constants/theme'

const Yoga = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Find Balance and Inner Peace with Yoga: Strengthen Your Body, Calm
            Your Mind!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service110} alt="" />
          </div>
          <p>
            Yoga is a holistic practice that integrates physical postures
            (asanas), breathing exercises (pranayama), and meditation to create
            a balanced connection between the body, mind, and spirit.
            Originating from ancient India, yoga has become a popular form of
            exercise and relaxation worldwide, known for its ability to enhance
            flexibility, build strength, and promote mental clarity. Whether
            you&apos;re holding a pose to build muscle endurance or focusing on
            your breath to calm your mind, yoga offers a unique approach to
            fitness that goes beyond the physical. It’s a practice that can be
            adapted to any fitness level, from beginner to advanced, and it
            provides a gentle yet effective way to improve overall well-being.
            For those new to yoga, it serves as an excellent introduction to
            mindful movement and stress relief, while seasoned practitioners can
            deepen their practice by exploring more advanced poses and
            meditation techniques.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Improved Flexibility: </strong>Regular practice increases
              flexibility and range of motion, reducing stiffness and tension.
            </li>
            <li>
              <strong>Enhanced Strength: </strong>Yoga builds strength in key
              areas, particularly the core, legs, and upper body, through
              sustained poses.
            </li>
            <li>
              <strong>Stress Relief: </strong>The combination of mindful
              movement and controlled breathing helps reduce stress and anxiety.
            </li>
            <li>
              <strong>Better Balance and Coordination: </strong>Yoga improves
              balance and coordination, enhancing physical stability.
            </li>
            <li>
              <strong>Mental Clarity: </strong>The meditative aspects of yoga
              foster mental clarity, focus, and emotional resilience.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start Slow: </strong>Begin with beginner classes or
                poses to build a strong foundation before progressing to more
                advanced techniques.
              </li>
              <li>
                <strong>Practice Consistently: </strong>Regular practice, even
                if short, is key to experiencing the full benefits of yoga.
              </li>
              <li>
                <strong>Listen to Your Body: </strong>Pay attention to your
                body’s signals and modify poses as needed to avoid injury.
              </li>
              <li>
                <strong>Incorporate Breathwork: </strong>Focus on your breath
                during each pose to enhance relaxation and mindfulness.
              </li>
              <li>
                <strong>Explore Different Styles: </strong>Experiment with
                various yoga styles, such as Hatha, Vinyasa, or Yin, to find the
                one that resonates best with you.
              </li>
            </ul>
          </div>
          {/* <div className="col-xl-6 m-b30">
              <div className="dz-media">
                <img src={IMAGES.bloggrid1} className="img-cover" alt="" />
              </div>
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default Yoga
