import React from 'react'
import FitnessGoal from '../components/FitnessGoal'
import { IMAGES } from '../constants/theme'
import FitnessTraining from '../elements/FitnessTraining'
import LatestSlider from '../elements/LatestSlider'
import NewsLetter from '../elements/NewsLetter'
import PageTitle from '../elements/PageTitle'
import ScheduleTable from '../elements/ScheduleTable'
import WorkoutSlider from '../elements/WorkoutSlider'
import { Link } from 'react-router-dom'
import { Tab, Nav } from 'react-bootstrap'
import OurTeam from '../components/OurTeam'
import ClientMoments from '../components/ClientMoments'
const MoreAboutUs = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="About Us" parentTitle="Pages" />
        <section
          className="content-inner"
          style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
        >
          <div className="container">
            <div className="row about-bx2 align-items-center">
              <FitnessGoal />
            </div>
          </div>
        </section>
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  )
}
export default MoreAboutUs
