// import React from 'react'
// import { Link } from 'react-router-dom'

// const cards = [
//   {
//     icon: 'flaticon-fitness',
//     title: 'Our Classes',
//     description: 'Explore our diverse fitness classes for all levels.',
//   },
//   {
//     icon: 'flaticon-user',
//     title: 'Our Trainers',
//     description: 'Meet our experienced and passionate fitness experts.',
//   },
//   {
//     icon: 'flaticon-medal',
//     title: 'Memberships',
//     description: 'Choose the membership that best fits your needs and budget.',
//   },
//   {
//     icon: 'flaticon-calendar',
//     title: 'Our Timeline',
//     description: 'See our upcoming events and fitness challenges.',
//   },
// ]
// const HomebannerCard = () => {
//   return (
//     <>
//       <div className="row align-items-center justify-content-center">
//         {cards.map((item, ind) => (
//           <div
//             className="col-xl-3 col-sm-6 mb-xl-0 mb-4 wow fadeInUp"
//             key={ind}
//           >
//             <div className="icon-bx-wraper style-4 bg-white">
//               <div className="icon-bx m-b20">
//                 <div className="icon-cell text-primary">
//                   <i className={item.icon}></i>
//                 </div>
//               </div>
//               <div className="icon-content">
//                 <h4 className="dz-title m-b10">
//                   <Link to="/pricing">{item.title}</Link>
//                 </h4>
//                 <p className="m-b15">{item.description}</p>
//                 <Link to="/pricing" className="read-more">
//                   Read More <i className="fa-solid fa-arrow-right"></i>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </>
//   )
// }

// export default HomebannerCard

import React from 'react'
import { Link } from 'react-router-dom'

const cards = [
  {
    icon: 'flaticon-fitness',
    title: 'Our Classes',
    description: 'Explore our diverse fitness classes for all levels.',
  },
  {
    icon: 'flaticon-user',
    title: 'Our Trainers',
    description: 'Meet our experienced and passionate fitness experts.',
  },
  {
    icon: 'flaticon-medal',
    title: 'Memberships',
    description: 'Choose the membership that best fits your needs and budget.',
  },
  {
    icon: 'flaticon-calendar',
    title: 'Our Timeline',
    description: 'See our upcoming events and fitness challenges.',
  },
]

const HomebannerCard = () => {
  return (
    <div className="row align-items-stretch">
      {cards.map((item, ind) => (
        <div className="col-xl-3 col-sm-6 mb-4 d-flex" key={ind}>
          <div className="icon-bx-wraper style-4 bg-white w-100 d-flex flex-column">
            <div className="icon-bx m-b20">
              <div className="icon-cell text-primary">
                <i className={item.icon}></i>
              </div>
            </div>
            <div className="icon-content d-flex flex-column flex-grow-1">
              <h4 className="dz-title m-b10">
                <Link to="/pricing">{item.title}</Link>
              </h4>
              <p className="m-b15 flex-grow-1">{item.description}</p>
              <Link to="/pricing" className="read-more mt-auto">
                Read More <i className="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomebannerCard
