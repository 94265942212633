import React from 'react'
import { IMAGES } from '../../constants/theme'

const TRX = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Suspension Training Redefined: Transform Your Body with TRX!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service108} alt="" />
          </div>
          <p>
            TRX, or Total Resistance exercise, is a revolutionary suspension
            training system that leverages gravity and your body weight to
            perform a wide range of exercises. Using a pair of adjustable straps
            anchored to a stable point, TRX allows you to modify the intensity
            of your workout by adjusting your body position. This form of
            training is ideal for developing core strength, balance,
            flexibility, and muscular endurance. TRX exercises are functional
            and can target multiple muscle groups simultaneously, making it a
            highly effective and efficient workout option. Whether you’re doing
            rows, push-ups, or lunges, TRX challenges your body in unique ways,
            enhancing your coordination and stability. It’s an excellent choice
            for both beginners and advanced athletes, as the exercises can be
            easily scaled to match any fitness level. TRX workouts are not only
            about strength; they also promote injury prevention by focusing on
            functional movements that improve your overall mobility and
            stability.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Full-Body Workout: </strong>Engages all major muscle
              groups through functional, body-weight exercises.
            </li>
            <li>
              <strong>Core Strength: </strong>TRX emphasizes core stability,
              helping you develop a stronger and more stable midsection.
            </li>
            <li>
              <strong>Improved Flexibility: </strong>The dynamic nature of TRX
              movements enhances your range of motion and flexibility.
            </li>
            <li>
              <strong>Scalability: </strong>Easily adjust the intensity of
              exercises to match your fitness level by changing your body angle.
            </li>
            <li>
              <strong>Portability: </strong>TRX straps are lightweight and
              portable, allowing you to perform a complete workout anywhere.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Master the Basics: </strong>Start with foundational
                exercises like rows and squats to build confidence and
                stability.
              </li>
              <li>
                <strong>Adjust Your Angle: </strong>To increase or decrease
                intensity, simply change the angle of your body relative to the
                ground.
              </li>
              <li>
                <strong>Focus on Core Engagement: </strong>Keep your core tight
                throughout each movement to maximize the benefits of TRX
                training.
              </li>
              <li>
                <strong>Incorporate TRX into Other Workouts: </strong>Use TRX as
                part of a larger workout routine, combining it with other forms
                of exercise for a well-rounded fitness plan.
              </li>
              <li>
                <strong>Practice Regularly: </strong>Consistency is key to
                improving strength, flexibility, and stability with TRX
                training.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TRX
