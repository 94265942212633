import React from 'react'
import { IMAGES } from '../../constants/theme'

const WeightTraining = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Build Strength, Sculpt Your Body: The Power of Weight Training!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service107} alt="" />
          </div>
          <p>
            Weight training, also known as resistance training, is a
            foundational fitness method that involves lifting weights to
            challenge and strengthen your muscles. By progressively increasing
            the amount of weight lifted, you can build muscle mass, enhance
            strength, and improve bone density. Weight training can be tailored
            to suit any fitness goal, whether you want to bulk up, tone your
            muscles, or increase endurance. This type of training typically
            includes exercises like bench presses, squats, deadlifts, and bicep
            curls, which target specific muscle groups. For those new to weight
            training, it’s important to start with lighter weights and focus on
            proper form to avoid injury. As you become more experienced, you can
            gradually increase the weight and complexity of your exercises.
            Weight training is not only about building muscles; it also boosts
            metabolism, improves posture, and enhances overall body mechanics,
            making everyday tasks easier and more efficient.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Increased Muscle Mass: </strong>Weight training stimulates
              muscle growth, helping you achieve a more defined and muscular
              physique.
            </li>
            <li>
              <strong>Improved Strength: </strong>Regular lifting increases your
              overall strength, making you stronger and more resilient.
            </li>
            <li>
              <strong>Enhanced Metabolism: </strong>Building muscle mass boosts
              your metabolic rate, aiding in fat loss and weight management.
            </li>
            <li>
              <strong>Bone Health: </strong>Lifting weights increases bone
              density, reducing the risk of osteoporosis and fractures.
            </li>
            <li>
              <strong>Better Posture and Balance: </strong>Strengthening core
              muscles improves posture and balance, enhancing your overall body
              mechanics.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start with Light Weights: </strong>If you’re new to
                weight training, begin with lighter weights to learn proper form
                and technique.
              </li>
              <li>
                <strong>Focus on Compound Movements: </strong>Exercises like
                squats, deadlifts, and bench presses engage multiple muscle
                groups and provide the best results.
              </li>
              <li>
                <strong>Prioritize Form Over Weight: </strong>SAlways maintain
                proper form to prevent injury, especially as you start lifting
                heavier weights.
              </li>
              <li>
                <strong>Incorporate Rest Days: </strong>Allow your muscles to
                recover by incorporating rest days between weight training
                sessions.
              </li>
              <li>
                <strong>Consider a Training Plan: </strong>Following a
                structured weight training plan can help you stay on track and
                achieve your fitness goals more efficiently.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeightTraining
