import React from 'react'
import { IMAGES } from '../../constants/theme'

const Zumba = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Dance Your Way to Fitness with Zumba: Fun, Energy, Results!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service105} alt="Zumba Image" />
          </div>
          <p>
            Zumba is a popular fitness program that combines Latin and
            international music with dance moves to create an exhilarating
            workout experience. It&apos;s designed to get your heart pumping and
            your body moving, making it an effective way to improve
            cardiovascular health, burn calories, and tone muscles. The best
            part about Zumba is that it feels more like a dance party than a
            traditional workout, with easy-to-follow choreography that caters to
            all fitness levels. Whether you&apos;re a seasoned dancer or have
            two left feet, Zumba allows you to let loose and enjoy the rhythm
            while working up a sweat. The routines incorporate interval
            training—alternating between fast and slow rhythms—to help improve
            endurance and maximize calorie burn. Zumba is not just a workout;
            it&apos;s a community, where you can connect with others who share a
            passion for fitness and fun.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Burn Calories: </strong>Zumba&apos;s high-energy dance
              moves help burn a significant number of calories, aiding in weight
              loss.
            </li>
            <li>
              <strong>Cardio and Core Strength: </strong>The workout targets the
              core while improving heart health through continuous movement.
            </li>
            <li>
              <strong>Stress Relief: </strong>Dancing to lively music boosts
              your mood and reduces stress levels.
            </li>
            <li>
              <strong>Increased Coordination: </strong>The varied dance routines
              enhance coordination and flexibility.
            </li>
            <li>
              <strong>Social Engagement: </strong>Zumba classes foster a sense
              of community, making fitness fun and socially rewarding.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Wear Breathable Clothing: </strong>Choose light,
                comfortable clothing that allows for freedom of movement.
              </li>
              <li>
                <strong>Hydrate: </strong>Keep water handy to stay hydrated
                during this energetic workout.
              </li>
              <li>
                <strong>Don’t Worry About Perfection: </strong>Focus on enjoying
                the music and moving your body rather than perfecting every
                step.
              </li>
              <li>
                <strong>Start at Your Own Pace: </strong>If you’re new to Zumba,
                start with beginner classes and gradually move to more advanced
                levels.
              </li>
              <li>
                <strong>Invite Friends: </strong>Enhance the fun by bringing
                friends to join in on the dance party.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Zumba
