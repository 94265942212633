// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import ClientSlider from '../components/ClientSlider'
// import { IMAGES, SVGICON } from '../constants/theme'
// import LatestSlider from '../elements/LatestSlider'
// import NewsLetter from '../elements/NewsLetter'
// import PageTitle from '../elements/PageTitle'
// import WorkoutSlider from '../elements/WorkoutSlider'

// const wrapperBlog = [
//   {
//     title: 'Crossfit',
//     image: IMAGES.Service101,
//     description:
//       'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
//   },
//   {
//     title: 'Circuit Training',
//     image: IMAGES.Service102,
//     description:
//       'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
//   },

//   {
//     title: 'Aerobics',
//     image: IMAGES.Service104,
//     description:
//       'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
//   },
//   {
//     title: 'Zumba',
//     image: IMAGES.Service105,
//     description:
//       'Zumba is a high-energy, dance-based workout that combines Latin rhythms with cardio exercises to create a fun, calorie-burning fitness experience.',
//   },

//   {
//     title: 'Weight Training',
//     image: IMAGES.Service107,
//     description:
//       'Weight training involves lifting weights to build muscle mass, increase strength, and enhance overall physical performance.',
//   },
//   {
//     title: 'TRX',
//     image: IMAGES.Service108,
//     description:
//       'TRX is a versatile suspension training system that uses your body weight to build strength, flexibility, and stability.',
//   },
//   {
//     title: 'Power Yoga',
//     image: IMAGES.Service111,
//     description:
//       'Power Yoga is an intense, fast-paced form of yoga that combines strength, flexibility, and cardio for a full-body workout.',
//   },
//   {
//     title: 'Pilates',
//     image: IMAGES.Service112,
//     description:
//       'Pilates is a low-impact exercise system focused on improving core strength, flexibility, and overall body alignment.',
//   },
//   {
//     title: 'Calisthenics',
//     image: IMAGES.Service106,
//     description:
//       'Calisthenics is a form of strength training that uses your body weight to build muscle, improve flexibility, and enhance overall physical fitness.',
//   },
//   {
//     title: 'Yoga',
//     image: IMAGES.Service110,
//     description:
//       'Yoga combines physical postures, breathing techniques, and meditation to promote physical strength, flexibility, and mental well-being.',
//   },
//   {
//     title: 'Functional Training',
//     image: IMAGES.Service109,
//     description:
//       'Functional Training enhances your ability to perform daily activities by focusing on movements that improve strength, balance, and coordination.',
//   },
//   {
//     title: 'Bollywood Dance',
//     image: IMAGES.Service103,
//     description:
//       'Bollywood Fitness Dance combines energetic dance moves with vibrant music to create a fun, full-body workout that feels more like a party than exercise.',
//   },
// ]

// const Services = () => {
//   const [hover, setHover] = useState(0)
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Services" parentTitle="Services" />
//         <section
//           className="content-inner overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="section-head style-1 text-center">
//               {/* <h4 className="sub-text text-muted">Client</h4> */}
//               <h2 className="title">
//                 Our <span className="text-primary">Training Programs</span>
//               </h2>
//             </div>
//             <div className="row">
//               {wrapperBlog.map((item, index) => (
//                 <div className="col-xl-3 col-md-6 m-b30" key={index}>
//                   <div
//                     className={`icon-bx-wraper style-7 box-hover ${
//                       hover === index ? 'active' : ''
//                     }`}
//                     onMouseEnter={() => setHover(index)}
//                   >
//                     <div className="icon-bx m-b30">
//                       <span className="icon-cell">
//                         <img src={item.image} alt="" />
//                       </span>
//                     </div>
//                     <div className="icon-content">
//                       <h5 className="dz-title m-b10">
//                         <Link to={'#'}>{item.title}</Link>
//                       </h5>
//                       <p className="m-b25">{item.description}</p>
//                       <Link
//                         to={'/services-details'}
//                         className="btn btn-primary shadow-primary btn-skew"
//                       >
//                         <span>Read More</span>
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>
//         <section className="content-inner portfolio-wrapper">
//           <div className="portfolio-wrapper-inner">
//             <div className="container-fluid  p-0">
//               <WorkoutSlider />
//             </div>
//           </div>
//           <svg
//             className="shape-up"
//             width="635"
//             height="107"
//             viewBox="0 0 635 107"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary-dark)" />
//           </svg>
//           <svg
//             className="shape-down"
//             width="673"
//             height="109"
//             viewBox="0 0 673 109"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)" />
//           </svg>
//         </section>
//         <section
//           className="content-inner-1 testimonial-wrapper1"
//           data-text="FEEDBACK"
//           style={{
//             backgroundImage: 'url(' + IMAGES.BgImage2 + ')',
//             backgroundPosition: 'center',
//           }}
//         >
//           <div className="container">
//             <div className="section-head text-center">
//               <h5 className="sub-title">TESTIMONIAL</h5>
//               <h2 className="title">What Client Say’s</h2>
//             </div>
//             <ClientSlider />
//           </div>
//           <div className="avatar1">
//             <img src={IMAGES.avatarlarge1} alt="" />
//           </div>
//           <div className="avatar2">
//             <img src={IMAGES.avatarlarge2} alt="" />
//           </div>
//           <div className="avatar3">
//             <img src={IMAGES.avatar3} alt="" />
//           </div>
//           <div className="avatar4">
//             <img src={IMAGES.avatarlarge1} alt="" />
//           </div>
//           <div className="avatar5">
//             <img src={IMAGES.avatarlarge2} alt="" />
//           </div>
//           <div className="avatar6">
//             <img src={IMAGES.avatar3} alt="" />
//           </div>
//           <img
//             className="svg-shape rotate-360"
//             src={SVGICON.circlebigSvg1}
//             alt=""
//           />
//           <img
//             className="svg-shape-2 rotate-360"
//             src={SVGICON.circlebigSvg2}
//             alt=""
//           />
//         </section>
//         <section
//           className="content-inner-1 overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <LatestSlider />
//         </section>
//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Services

// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import { IMAGES, SVGICON } from '../constants/theme'
// import PageTitle from '../elements/PageTitle'
// import NewsLetter from '../elements/NewsLetter'

// const programs = [
//   {
//     title: 'Crossfit',
//     image: IMAGES.Service101,
//     description:
//       'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
//   },
//   {
//     title: 'Circuit Training',
//     image: IMAGES.Service102,
//     description:
//       'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
//   },

//   {
//     title: 'Aerobics',
//     image: IMAGES.Service104,
//     description:
//       'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
//   },
//   {
//     title: 'Zumba',
//     image: IMAGES.Service105,
//     description:
//       'Zumba is a high-energy, dance-based workout that combines Latin rhythms with cardio exercises to create a fun, calorie-burning fitness experience.',
//   },

//   {
//     title: 'Weight Training',
//     image: IMAGES.Service107,
//     description:
//       'Weight training involves lifting weights to build muscle mass, increase strength, and enhance overall physical performance.',
//   },
//   {
//     title: 'TRX',
//     image: IMAGES.Service108,
//     description:
//       'TRX is a versatile suspension training system that uses your body weight to build strength, flexibility, and stability.',
//   },
//   {
//     title: 'Power Yoga',
//     image: IMAGES.Service111,
//     description:
//       'Power Yoga is an intense, fast-paced form of yoga that combines strength, flexibility, and cardio for a full-body workout.',
//   },
//   {
//     title: 'Pilates',
//     image: IMAGES.Service112,
//     description:
//       'Pilates is a low-impact exercise system focused on improving core strength, flexibility, and overall body alignment.',
//   },
//   {
//     title: 'Calisthenics',
//     image: IMAGES.Service106,
//     description:
//       'Calisthenics is a form of strength training that uses your body weight to build muscle, improve flexibility, and enhance overall physical fitness.',
//   },
//   {
//     title: 'Yoga',
//     image: IMAGES.Service110,
//     description:
//       'Yoga combines physical postures, breathing techniques, and meditation to promote physical strength, flexibility, and mental well-being.',
//   },
//   {
//     title: 'Functional Training',
//     image: IMAGES.Service109,
//     description:
//       'Functional Training enhances your ability to perform daily activities by focusing on movements that improve strength, balance, and coordination.',
//   },
//   {
//     title: 'Bollywood Dance',
//     image: IMAGES.Service103,
//     description:
//       'Bollywood Fitness Dance combines energetic dance moves with vibrant music to create a fun, full-body workout that feels more like a party than exercise.',
//   },
// ]

// const Services = () => {
//   const [hover, setHover] = useState(0)
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Services" parentTitle="Services" />
//         <section
//           className="content-inner overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="section-head style-1 text-center">
//               {/* <h4 className="sub-text text-muted">Client</h4> */}
//               <h2 className="title">
//                 Our <span className="text-primary">Training Programs</span>
//               </h2>
//             </div>
//             <div className="row">
//               {programs.map((item, index) => (
//                 <div className="col-xl-3 col-md-6 m-b30" key={index}>
//                   <div
//                     className={`icon-bx-wraper style-7 box-hover ${
//                       hover === index ? 'active' : ''
//                     }`}
//                     onMouseEnter={() => setHover(index)}
//                   >
//                     <div className="icon-bx m-b30">
//                       <span className="icon-cell">
//                         <img src={item.image} alt="" />
//                       </span>
//                     </div>
//                     <div className="icon-content">
//                       <h5 className="dz-title m-b10">
//                         <Link to={'#'}>{item.title}</Link>
//                       </h5>
//                       <p className="m-b25">{item.description}</p>
//                       <Link
//                         to={'/services-details'}
//                         className="btn btn-primary shadow-primary btn-skew"
//                       >
//                         <span>Read More</span>
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>

//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Services

// import React, { useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import { IMAGES, SVGICON } from '../constants/theme'
// import PageTitle from '../elements/PageTitle'
// import NewsLetter from '../elements/NewsLetter'

// const programs = [
//   {
//     id: 'crossfit',
//     title: 'Crossfit',
//     image: IMAGES.Service101,
//     description:
//       'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
//   },
//   {
//     id: 'circuit-training',
//     title: 'Circuit Training',
//     image: IMAGES.Service102,
//     description:
//       'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
//   },

//   {
//     id: 'aerobics',
//     title: 'Aerobics',
//     image: IMAGES.Service104,
//     description:
//       'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
//   },
// ]

// const Services = () => {
//   const [hover, setHover] = useState(0)
//   const navigate = useNavigate()

//   // const handleReadMore = (programTitle) => {
//   //   // Converting program title to URL-friendly format
//   //   const formattedTitle = programTitle.toLowerCase().replace(/\s+/g, '-')
//   //   navigate(`/services/${formattedTitle}`)
//   // }

//   const handleReadMore = (programId) => {
//     navigate(`/services/${programId}`)
//   }
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Services" parentTitle="Services" />
//         <section
//           className="content-inner overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="section-head style-1 text-center">
//               <h2 className="title">
//                 Our <span className="text-primary">Training Programs</span>
//               </h2>
//             </div>
//             <div className="row">
//               {programs.map((item, index) => (
//                 <div className="col-xl-3 col-md-6 m-b30" key={item.id}>
//                   <div
//                     className={`icon-bx-wraper style-7 box-hover ${
//                       hover === index ? 'active' : ''
//                     }`}
//                     onMouseEnter={() => setHover(index)}
//                   >
//                     <div className="icon-bx m-b30">
//                       <span className="icon-cell">
//                         <img src={item.image} alt="" />
//                       </span>
//                     </div>
//                     <div className="icon-content">
//                       <h5 className="dz-title m-b10">{item.title}</h5>
//                       <p className="m-b25">{item.description}</p>
//                       <button
//                         onClick={() => handleReadMore(item.id)}
//                         className="btn btn-primary shadow-primary btn-skew"
//                       >
//                         <span>Read More</span>
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>

//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Services

// import React, { useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import { IMAGES } from '../constants/theme'
// import PageTitle from '../elements/PageTitle'
// import NewsLetter from '../elements/NewsLetter'

// const programs = [
//   {
//     id: 'crossfit',
//     title: 'Crossfit',
//     image: IMAGES.Service101,
//     description:
//       'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
//   },
//   {
//     id: 'circuit-training',
//     title: 'Circuit Training',
//     image: IMAGES.Service102,
//     description:
//       'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
//   },

//   {
//     id: 'aerobics',
//     title: 'Aerobics',
//     image: IMAGES.Service104,
//     description:
//       'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
//   },
//   {
//     id: 'zumba',
//     title: 'Zumba',
//     image: IMAGES.Service105,
//     description:
//       'Zumba is a high-energy, dance-based workout that combines Latin rhythms with cardio exercises to create a fun, calorie-burning fitness experience.',
//   },

//   {
//     id: 'weight-training',
//     title: 'Weight Training',
//     image: IMAGES.Service107,
//     description:
//       'Weight training involves lifting weights to build muscle mass, increase strength, and enhance overall physical performance.',
//   },
//   {
//     id: 'trx',
//     title: 'TRX',
//     image: IMAGES.Service108,
//     description:
//       'TRX is a versatile suspension training system that uses your body weight to build strength, flexibility, and stability.',
//   },
//   {
//     id: 'power-yoga',
//     title: 'Power Yoga',
//     image: IMAGES.Service111,
//     description:
//       'Power Yoga is an intense, fast-paced form of yoga that combines strength, flexibility, and cardio for a full-body workout.',
//   },
//   {
//     id: 'pilates',
//     title: 'Pilates',
//     image: IMAGES.Service112,
//     description:
//       'Pilates is a low-impact exercise system focused on improving core strength, flexibility, and overall body alignment.',
//   },
//   {
//     id: 'calisthenics',
//     title: 'Calisthenics',
//     image: IMAGES.Service106,
//     description:
//       'Calisthenics is a form of strength training that uses your body weight to build muscle, improve flexibility, and enhance overall physical fitness.',
//   },
//   {
//     id: 'yoga',
//     title: 'Yoga',
//     image: IMAGES.Service110,
//     description:
//       'Yoga combines physical postures, breathing techniques, and meditation to promote physical strength, flexibility, and mental well-being.',
//   },
//   {
//     id: 'fuctional-training',
//     title: 'Functional Training',
//     image: IMAGES.Service109,
//     description:
//       'Functional Training enhances your ability to perform daily activities by focusing on movements that improve strength, balance, and coordination.',
//   },
//   {
//     id: 'bollywood-dance',
//     title: 'Bollywood Dance',
//     image: IMAGES.Service103,
//     description:
//       'Bollywood Fitness Dance combines energetic dance moves with vibrant music to create a fun, full-body workout that feels more like a party than exercise.',
//   },
// ]

// const Services = () => {
//   const [hover, setHover] = useState(0)
//   const navigate = useNavigate()

//   const handleReadMore = (programId) => {
//     console.log('Program clicked:', programId)
//     navigate(`/services/${programId}`)
//   }

//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageTitle activePage="Services" parentTitle="Services" />
//         <section
//           className="content-inner overflow-hidden"
//           style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
//         >
//           <div className="container">
//             <div className="section-head style-1 text-center">
//               <h2 className="title">
//                 Our <span className="text-primary">Training Programs</span>
//               </h2>
//             </div>
//             <div className="row">
//               {programs.map((item, index) => (
//                 <div className="col-xl-4 col-md-6 m-b30 px-8" key={item.id}>
//                   <div
//                     className={`icon-bx-wraper style-7 box-hover ${
//                       hover === index ? 'active' : ''
//                     }`}
//                     onMouseEnter={() => setHover(index)}
//                   >
//                     <div className="icon-bx m-b30">
//                       <span className="icon-cell">
//                         <img src={item.image} alt="" />
//                       </span>
//                     </div>
//                     <div className="icon-content">
//                       <h5 className="dz-title m-b10">{item.title}</h5>
//                       <p className="m-b25">{item.description}</p>
//                       <button
//                         onClick={() => handleReadMore(item.id)}
//                         className="btn btn-primary shadow-primary btn-skew"
//                       >
//                         <span>Read More</span>
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>

//         <section className="call-action style-1 footer-action">
//           <div className="container">
//             <NewsLetter />
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Services

// -----------

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IMAGES } from '../constants/theme'
import PageTitle from '../elements/PageTitle'
import NewsLetter from '../elements/NewsLetter'

const programs = [
  {
    id: 'crossfit',
    title: 'Crossfit',
    image: IMAGES.Service101,
    description:
      'CrossFit is an intense, full-body workout combining strength, endurance, and agility to push your limits and achieve peak fitness.',
  },
  {
    id: 'circuit-training',
    title: 'Circuit Training',
    image: IMAGES.Service102,
    description:
      'Circuit Training is an efficient workout method that combines strength and cardio exercises to target all major muscle groups in one session.',
  },
  {
    id: 'aerobics',
    title: 'Aerobics',
    image: IMAGES.Service104,
    description:
      'Aerobics is a rhythmic, high-energy workout designed to improve cardiovascular health, burn calories, and boost overall fitness.',
  },
  {
    id: 'zumba',
    title: 'Zumba',
    image: IMAGES.Service105,
    description:
      'Zumba is a high-energy, dance-based workout that combines Latin rhythms with cardio exercises to create a fun, calorie-burning fitness experience.',
  },
  {
    id: 'weight-training',
    title: 'Weight Training',
    image: IMAGES.Service107,
    description:
      'Weight training involves lifting weights to build muscle mass, increase strength, and enhance overall physical performance.',
  },
  {
    id: 'trx',
    title: 'TRX',
    image: IMAGES.Service108,
    description:
      'TRX is a versatile suspension training system that uses your body weight to build strength, flexibility, and stability.',
  },
  {
    id: 'power-yoga',
    title: 'Power Yoga',
    image: IMAGES.Service111,
    description:
      'Power Yoga is an intense, fast-paced form of yoga that combines strength, flexibility, and cardio for a full-body workout.',
  },
  {
    id: 'pilates',
    title: 'Pilates',
    image: IMAGES.Service112,
    description:
      'Pilates is a low-impact exercise system focused on improving core strength, flexibility, and overall body alignment.',
  },
  {
    id: 'calisthenics',
    title: 'Calisthenics',
    image: IMAGES.Service106,
    description:
      'Calisthenics is a form of strength training that uses your body weight to build muscle, improve flexibility, and enhance overall physical fitness.',
  },
  {
    id: 'yoga',
    title: 'Yoga',
    image: IMAGES.Service110,
    description:
      'Yoga combines physical postures, breathing techniques, and meditation to promote physical strength, flexibility, and mental well-being.',
  },
  {
    id: 'functional-training',
    title: 'Functional Training',
    image: IMAGES.Service109,
    description:
      'Functional Training enhances your ability to perform daily activities by focusing on movements that improve strength, balance, and coordination.',
  },
  {
    id: 'bollywood-dance',
    title: 'Bollywood Dance',
    image: IMAGES.Service103,
    description:
      'Bollywood Fitness Dance combines energetic dance moves with vibrant music to create a fun, full-body workout that feels more like a party than exercise.',
  },
]

const Services = () => {
  const [hover, setHover] = useState(0)
  const navigate = useNavigate()

  const handleReadMore = (programId) => {
    console.log('Program clicked:', programId)
    navigate(`/services/${programId}`)
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Services" parentTitle="Services" />
        <section
          className="content-inner overflow-hidden"
          style={{ backgroundImage: 'url(' + IMAGES.BgImage1 + ')' }}
        >
          <div className="container">
            <div className="section-head style-1 text-center mb-5">
              <h2 className="title">
                Our <span className="text-primary">Training Programs</span>
              </h2>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {programs.map((item, index) => (
                <div className="col" key={item.id}>
                  <div
                    className={`card h-100 border-1 shadow-lg ${
                      hover === index ? 'shadow-lg' : ''
                    }`}
                    onMouseEnter={() => setHover(index)}
                  >
                    <div
                      className="card-img-top position-relative overflow-hidden"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleReadMore(item.id)}
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-100 h-auto transition-all duration-300 transform hover:scale-110"
                        style={{
                          transition: 'transform 0.3s ease',
                        }}
                      />
                      <div
                        className="position-absolute top-0 start-0 w-100 h-100 hover:bg-black bg-opacity-5 transition-all duration-300"
                        style={{
                          transition: 'background-color 0.3s ease',
                        }}
                      />
                    </div>
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title mb-3">{item.title}</h5>
                      <p className="card-text flex-grow-1">
                        {item.description}
                      </p>
                      <button
                        onClick={() => handleReadMore(item.id)}
                        className="btn btn-primary shadow-primary btn-skew w-auto mt-3"
                      >
                        <span>Read More</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  )
}

export default Services
