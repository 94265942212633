import React, { useRef } from 'react'
import { IMAGES } from '../constants/theme'
import { Swiper, SwiperSlide } from 'swiper/react'

const GallerySlider = ({ data }) => {
  const swiperRef = useRef(null)
  return (
    <>
      <div className="swiper portfolio-slider-2">
        <Swiper
          slidesPerView={4}
          spaceBetween={20}
          ref={swiperRef}
          speed={1500}
          breakpoints={{
            1200: { slidesPerView: 4 },
            991: { slidesPerView: 3 },
            775: { slidesPerView: 2 },
            240: { slidesPerView: 1 },
          }}
          className="swiper-wrapper"
        >
          {data.map((item, ind) => (
            <SwiperSlide className="swiper-slide" key={ind}>
              <div className="image-tooltip-effect dz-box style-3 wow fadeInUp">
                <div className="dz-media">
                  <img src={item.img} alt={`${item.name} transformation`} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          onClick={() => {
            swiperRef.current.swiper.slidePrev()
          }}
          className="portfolio-2-button-prev btn-prev"
        >
          <i className="fas fa-chevron-left"></i>
        </div>
        <div
          onClick={() => {
            swiperRef.current.swiper.slideNext()
          }}
          className="portfolio-2-button-next btn-next"
        >
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    </>
  )
}

export default GallerySlider
