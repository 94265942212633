import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Nav } from 'react-bootstrap'
import { IMAGES } from '../constants/theme'

const FitnessGoal = ({ isOpenModal }) => {
  return (
    <>
      <div className="col-lg-6 about-content m-b30">
        <div className="section-head m-0">
          <span className="sub-title">ABOUT US</span>
          <h2 className="title">
            We Help To Get <span>Fitness</span> Goal
          </h2>
          <p className="m-0">
            Welcome to Zumbell Fitness, the pinnacle of health and fitness
            transformation in your city. At Prime Zumbell Fitness, we don&apos;t
            just believe in providing a gym; we offer a comprehensive wellness
            ecosystem designed to cater to all aspects of physical and mental
            well-being.
          </p>
        </div>
        <div className="" data-wow-delay="0.8s">
          <Tab.Container defaultActiveKey={'Mission'}>
            <Nav as="ul" className="nav nav-tabs style-1 m-b20 m-t30">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" eventKey={'Mission'}>
                  <span>Our Mission</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" eventKey={'Vision'}>
                  <span>Our Vision</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className="tab-content m-sm-b30 m-b40 p-r30"
              id="myTabContent"
            >
              <Tab.Pane eventKey={'Mission'}>
                <div className="content">
                  <p>
                    Our mission is to empower individuals of all ages to achieve
                    their fitness goals, embrace a healthier lifestyle, and
                    unlock their full potential.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={'Vision'}>
                <div className="content">
                  <p>
                    Our vision is to be the ultimate hub for fitness
                    enthusiasts, providing a seamless and inspiring online
                    experience that empowers individuals to embark on their
                    fitness journeys with confidence and achieve their full
                    potential.
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className="contact-us">
          <span className="icon">
            <i className="fa-solid fa-phone"></i>
          </span>
          <div className="content">
            <span>Call us for help</span>
            <h4 className="number">+91 8310728524</h4>
          </div>
        </div>
      </div>
      <div className="col-lg-6 m-b30">
        <div className="dz-media">
          <div className="image-box">
            <div className="video-bx1 h-auto w-auto overflow-visible">
              <img src={IMAGES.boxpic1} alt="" />
              <div className="video-btn sm">
                <Link
                  to={'https://youtu.be/UUwmlaDT-hM?si=BLuzQ3Wyi2i6AynD'}
                  className="popup-youtube"
                  onClick={() => isOpenModal(true)}
                >
                  <i className="fa fa-play" />
                </Link>
              </div>
            </div>
            <div className="info-box">
              <span>
                <i className="flaticon-play text-primary"></i> High Quality
                Video
              </span>
            </div>
          </div>
          <div className="image-box">
            <img src={IMAGES.boxpic2} alt="" />
            <div className="info-box">
              <span>
                <i className="flaticon-athletics text-primary"></i> Proffesional
                Trainer
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FitnessGoal
