import React from 'react'
import { IMAGES } from '../../constants/theme'

const Calisthenics = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Master Your Body with Calisthenics: Strength, Flexibility, Freedom!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service106} alt="" />
          </div>
          <p>
            Calisthenics is a highly effective form of exercise that relies on
            using your own body weight to perform various movements, such as
            push-ups, pull-ups, squats, and dips. Unlike traditional weight
            training, which uses external weights, calisthenics focuses on
            mastering your body&apos;s strength and movement. The exercises
            range from basic to advanced, allowing you to progressively
            challenge yourself as you gain strength and flexibility.
            Calisthenics can be done anywhere, as it requires minimal to no
            equipment, making it an accessible workout option for everyone. This
            training method not only builds muscle and burns fat but also
            enhances balance, coordination, and overall athleticism. For those
            new to fitness, calisthenics offers a solid foundation, helping you
            develop the core strength and body control necessary for more
            complex movements and exercises.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Full-Body Strength: </strong>Develops muscle and strength
              across all major muscle groups using natural body movements.
            </li>
            <li>
              <strong>Improved Flexibility: </strong>Increases range of motion
              and joint flexibility through dynamic body movements.
            </li>
            <li>
              <strong>Functional Fitness: </strong>Enhances everyday physical
              performance by training movements that mirror real-life
              activities.
            </li>
            <li>
              <strong>Portability: </strong>Requires minimal or no equipment,
              allowing you to work out anytime, anywhere.
            </li>
            <li>
              <strong>Scalability: </strong>Suitable for all fitness levels,
              from beginners to advanced, with the ability to progress to more
              challenging exercises.
            </li>
          </ul>
        </div>

        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start with Basic Movements: </strong>Focus on mastering
                basic exercises like push-ups, squats, and pull-ups before
                advancing to more complex movements.
              </li>
              <li>
                <strong>Consistency is Key: </strong>Regular practice is
                essential for building strength and progressing to more
                challenging exercises.
              </li>
              <li>
                <strong>Warm-Up Properly: </strong>Always begin with a thorough
                warm-up to prepare your muscles and joints for the workout.
              </li>
              <li>
                <strong>Focus on Form: </strong>Prioritize proper technique to
                avoid injury and maximize the effectiveness of each exercise.
              </li>
              <li>
                <strong>Progress Gradually: </strong>As you build strength and
                confidence, challenge yourself with more advanced movements like
                muscle-ups or handstands.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calisthenics
