import React from 'react'
import { IMAGES } from '../../constants/theme'

const CircuitTraining = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Maximize Your Fitness in Minimal Time with Circuit Training!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service102} alt="" />
          </div>
          <p>
            Circuit Training is a highly effective workout strategy that
            involves performing a series of exercises in rapid succession with
            minimal rest between them. Each &quot;circuit&quot; typically
            consists of 6 to 10 different exercises, focusing on different
            muscle groups or combining cardio and strength training elements.
            The idea is to keep your heart rate up while working various parts
            of your body, making it a great way to build strength, improve
            endurance, and burn calories in a short amount of time. Circuit
            Training is highly adaptable, meaning it can be tailored to suit any
            fitness level or goal, from weight loss to muscle gain. For
            beginners, it provides a structured, time-efficient workout that can
            be done at your own pace, making it an excellent option for those
            who want to make the most out of their gym time.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Full-Body Workout: </strong>Engages multiple muscle
              groups, promoting balanced strength and conditioning.
            </li>
            <li>
              <strong>Time-Efficient: </strong>Delivers a high-intensity workout
              in a short period, perfect for busy schedules.
            </li>
            <li>
              <strong>Boosted Metabolism: </strong>The combination of strength
              and cardio exercises helps burn calories during and after the
              workout.
            </li>
            <li>
              <strong>Improved Endurance: </strong>Keeps your heart rate
              elevated, improving cardiovascular health and stamina.
            </li>
            <li>
              <strong>Variety: </strong>Prevents workout boredom by
              incorporating diverse exercises in each circuit.
            </li>
          </ul>
        </div>
        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Customize Your Circuit: </strong>Tailor the exercises to
                your fitness goals and adjust the intensity as needed.
              </li>
              <li>
                <strong>Focus on Form: </strong>Ensure you’re using the correct
                technique, especially when moving quickly from one exercise to
                the next.
              </li>
              <li>
                <strong>Pace Yourself: </strong>Start with fewer circuits and
                shorter intervals, gradually increasing as your fitness
                improves.
              </li>
              <li>
                <strong>Incorporate Rest: </strong>Allow for short rest periods
                between circuits to maintain energy and performance.
              </li>
              <li>
                <strong>Track Progress: </strong>Monitor your performance over
                time by noting improvements in strength, endurance, and speed.
              </li>
            </ul>
          </div>
          {/* <div className="col-xl-6 m-b30">
            <div className="dz-media">
              <img src={IMAGES.bloggrid1} className="img-cover" alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default CircuitTraining
