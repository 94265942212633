// import React, { useRef, useState } from 'react'
// import emailjs from '@emailjs/browser'
// import Swal from 'sweetalert2'
// import PageTitle from '../elements/PageTitle'

// const UserRegistration = () => {
//   const form = useRef()
//   const [showPassword, setShowPassword] = useState(false)
//   const sendEmail = (e) => {
//     e.preventDefault()
//     emailjs
//       .sendForm(
//         'service_gfykn6i',
//         'template_iy1pb0b',
//         e.target,
//         'HccoOtZS6GHw-N-m6'
//       )
//       .then(
//         (result) => {
//           console.log(result.text)
//         },
//         (error) => {
//           console.log(error.text)
//         }
//       )
//     e.target.reset()
//     Swal.fire({
//       title: 'Good job!',
//       text: 'form successfuly submmited',
//       icon: 'success',
//     })
//   }

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword)
//   }
//   return (
//     <>
//       <div className="page-content">
//         <PageTitle activePage="User Registration" parentTitle="Membership" />
//         <section className="content-inner pt-3">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-lg-8 col-md-10 col-xl-6">
//                 <form
//                   className="dz-form dzForm style-2 bg-primary position-relative form-bottom"
//                   method="POST"
//                   ref={form}
//                   onSubmit={sendEmail}
//                 >
//                   <div className="form-head text-center mb-4">
//                     <h2 className="title m-0">User Information</h2>
//                   </div>
//                   <div className="row flex-column">
//                     <div className="col-md-12 mb-1 ">
//                       <div className="justify-content-center input-area ">
//                         <label>User Name</label>
//                         <div className="input-group input-line">
//                           <input
//                             name="dzFirstName"
//                             required
//                             type="text"
//                             className="form-control"
//                             placeholder="Enter Your User Name"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-12 mb-1">
//                       <div className="input-area">
//                         <label>Email Address</label>
//                         <div className="input-group input-line">
//                           <input
//                             name="dzEmail"
//                             required
//                             type="email"
//                             className="form-control"
//                             placeholder="example@gmail.com"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-12 mb-1">
//                       <div className="input-area">
//                         <label>Password</label>
//                         <div className="input-group input-line position-relative">
//                           <input
//                             name="dzPhoneNumber"
//                             required
//                             type={showPassword ? 'text' : 'password'}
//                             className="form-control w-100 pe-5"
//                             placeholder="Enter Your Password"
//                           />
//                           <button
//                             type="button"
//                             className="btn position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 text-white"
//                             onClick={togglePasswordVisibility}
//                           >
//                             {showPassword ? (
//                               <i className="fa fa-eye-slash"></i>
//                             ) : (
//                               <i className="fa fa-eye"></i>
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="form-head text-center mb-4">
//                       <h4 className="title m-0">Payment Information</h4>
//                     </div>
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center">
//                         <div className="form-check">
//                           <input
//                             className="form-check-input me-2"
//                             type="checkbox"
//                             value=""
//                             id="flexCheckDefault"
//                             required
//                           />
//                           <label
//                             className="form-check-label"
//                             htmlFor="flexCheckDefault"
//                           >
//                             Accept terms & conditions
//                           </label>
//                         </div>
//                         <button
//                           name="submit"
//                           type="submit"
//                           value="Submit"
//                           className="btn btn-light btn-skew"
//                         >
//                           <span>Submit</span>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }

// export default UserRegistration

import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'
import PageTitle from '../elements/PageTitle'
import { useLocation } from 'react-router-dom'
import { Check } from 'lucide-react'

const UserRegistration = () => {
  const location = useLocation()
  const selectedPlan = location.state?.selectedPlan || null
  const form = useRef()
  const [showPassword, setShowPassword] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_gfykn6i',
        'template_iy1pb0b',
        e.target,
        'HccoOtZS6GHw-N-m6'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )
    e.target.reset()
    Swal.fire({
      title: 'Good job!',
      text: 'form successfuly submmited',
      icon: 'success',
    })
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  return (
    <>
      <div className="page-content">
        <PageTitle activePage="User Registration" parentTitle="Membership" />
        <section className="content-inner pt-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-xl-6">
                <form
                  className="dz-form dzForm style-2 bg-primary position-relative form-bottom"
                  method="POST"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <div className="form-head text-center mb-4">
                    <h2 className="title m-0">User Information</h2>
                  </div>
                  <div className="row flex-column">
                    <div className="col-md-12 mb-1 ">
                      <div className="justify-content-center input-area ">
                        <label>First Name</label>
                        <div className="input-group input-line">
                          <input
                            name="dzFirstName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Enter Your First Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1 ">
                      <div className="justify-content-center input-area ">
                        <label>Last Name</label>
                        <div className="input-group input-line">
                          <input
                            name="dzFirstName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1 ">
                      <div className="justify-content-center input-area ">
                        <label>Phone</label>
                        <div className="input-group input-line">
                          <input
                            name="dzFirstName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Phone No."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="input-area">
                        <label>Email Address</label>
                        <div className="input-group input-line">
                          <input
                            name="dzEmail"
                            required
                            type="email"
                            className="form-control"
                            placeholder="example@gmail.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1 ">
                      <div className="justify-content-center input-area ">
                        <label>Address</label>
                        <div className="input-group input-line">
                          <input
                            name="dzFirstName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="input-area">
                        <label>Password</label>
                        <div className="input-group input-line position-relative">
                          <input
                            name="dzPhoneNumber"
                            required
                            type={showPassword ? 'text' : 'password'}
                            className="form-control w-100 pe-5"
                            placeholder="Enter Your Password"
                          />
                          <button
                            type="button"
                            className="btn position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 text-white"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <i className="fa fa-eye-slash"></i>
                            ) : (
                              <i className="fa fa-eye"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {selectedPlan && (
                      <div className="col-12 mb-4">
                        <div className="form-head text-center mb-4">
                          <h4 className="title m-0">
                            Selected Membership Plan
                          </h4>
                        </div>
                        <div className="text-white">
                          <div className="row">
                            <div className="col-md-10">
                              <p className="mb-2">
                                <strong>Package Name:</strong>{' '}
                                {selectedPlan.planTitle}
                              </p>
                              <p className="mb-2">
                                <strong>Duration:</strong>{' '}
                                {selectedPlan.duration}
                              </p>
                              <p className="mb-2">
                                <strong>Price:</strong> ₹{selectedPlan.price}/-
                              </p>
                            </div>
                            {/* <div className="col-md-6">
                              <strong>Plan Benefits:</strong>
                              <ul className="list-unstyled">
                                {selectedPlan.description.map(
                                  (benefit, index) => (
                                    <li key={index} className="mb-2">
                                      <Check
                                        className="text-white me-2"
                                        size={16}
                                      />
                                      {benefit}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Accept terms & conditions
                          </label>
                        </div>
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-light btn-skew"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default UserRegistration
