import React from 'react'
import PageTitle from '../elements/PageTitle'
import GallerySlider from '../components/GallerySlider'
import { team } from '../constants/team'
import { transformation } from '../constants/transformation'
import OtherPhotos from '../components/gallery/OtherPhotos'
const Gallery = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Gallery" parentTitle="Gallery" />

        <div className="pt-5 pb-5">
          <div className="section-head style-1 text-center m-0">
            <h2 className="title">
              <span>Our Team</span>
            </h2>
          </div>
          <section className="clearfix">
            <GallerySlider data={team} />
          </section>
        </div>

        <div className="pt-5 pb-5">
          <div className="section-head style-1 text-center m-0">
            <h2 className="title">
              <span>Transformation</span>
            </h2>
          </div>
          <section className="clearfix">
            <GallerySlider data={transformation} />
          </section>
        </div>

        <div className="pt-5 pb-5">
          <div className="section-head style-1 text-center m-0">
            <h2 className="title">
              <span>Other Photos</span>
            </h2>
          </div>
          <section className="clearfix">
            <OtherPhotos />
          </section>
        </div>
      </div>
    </>
  )
}

export default Gallery
