import React from 'react'
import { IMAGES } from '../../constants/theme'

const Aerobics = () => {
  return (
    <div className="col-xl-8 col-lg-7 order-lg-1">
      <div className="dz-content">
        <div className="m-b40">
          <h2 className="title m-b15">
            Get Moving with Aerobics: Energize Your Fitness Routine!
          </h2>
          <div className="dz-media m-b30">
            <img src={IMAGES.Service104} alt="" />
          </div>
          <p>
            Aerobics is a form of cardiovascular exercise that combines rhythmic
            aerobic movements with stretching and strength training routines,
            all performed to upbeat music. This high-energy workout is designed
            to improve your heart health, increase stamina, and burn calories,
            making it an excellent choice for weight loss and overall fitness.
            Aerobics classes typically involve a series of choreographed
            routines that include steps, jumps, and dance-like movements, which
            are easy to follow and suitable for all fitness levels. The
            continuous, rhythmic nature of aerobics helps to elevate your heart
            rate, improving circulation and lung capacity over time. Whether
            you&apos;re looking to shed pounds, tone your body, or simply enjoy
            a lively workout session, aerobics offers a fun and effective way to
            achieve your fitness goals.
          </p>
        </div>

        <div className="m-b40">
          <h4 className="m-b15">How Do I Get Benefit from the Training?</h4>
          <ul className="list-check-2 m-b30">
            <li>
              <strong>Improved Cardiovascular Health: </strong>Enhances heart
              and lung function through sustained aerobic activity.
            </li>
            <li>
              <strong>Calorie Burning: </strong>Helps in weight management by
              burning a significant number of calories in each session.
            </li>
            <li>
              <strong>Increased Stamina: </strong>Regular participation builds
              endurance, allowing you to perform physical activities with less
              fatigue.
            </li>
            <li>
              <strong>Enhanced Mood: </strong>The combination of music and
              movement boosts endorphin levels, reducing stress and improving
              mental well-being.
            </li>
            <li>
              <strong>Toned Muscles: </strong>The varied movements help tone
              muscles, particularly in the legs, arms, and core.
            </li>
          </ul>
        </div>
        <div className="row align-items-center">
          <div className="m-b30">
            <h4 className="m-b10">Suggestions</h4>
            <ul className="list-check-2 m-b30">
              <li>
                <strong>Start with Low-Impact: </strong>If you’re new to
                aerobics, begin with low-impact classes to build stamina and
                confidence.
              </li>
              <li>
                <strong>Wear Proper Footwear: </strong>Invest in supportive
                workout shoes to protect your joints during high-impact
                movements.
              </li>
              <li>
                <strong>Listen to Your Body: </strong>Modify movements as needed
                to avoid injury, especially if you’re just starting out.
              </li>
              <li>
                <strong>Stay Consistent: </strong>Regular attendance will help
                you see and feel the benefits faster.
              </li>
              <li>
                <strong>Pair with a Balanced Diet: </strong>Combine aerobics
                with a healthy diet for optimal weight management and fitness
                results.
              </li>
            </ul>
          </div>
          {/* <div className="col-xl-6 m-b30">
            <div className="dz-media">
              <img src={IMAGES.bloggrid1} className="img-cover" alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Aerobics
